import { handleSize } from 'elements/size';
import { handleSpacing } from 'elements/spacing';
import Link from 'next/link';
import styled from 'styled-components';
import { ButtonElement, StyledButtonPropType } from '../../elements';

export type StyledOwnPropType = {
  variant?: 'button' | 'link';
  size?: 'small' | 'medium' | 'large';
  color?: 'primary' | 'secondary' | 'tertiary';
};

export type StyledPropType = StyledOwnPropType & StyledButtonPropType;

export const ButtonStyled = styled(ButtonElement)<StyledPropType>`
  ${(props) => props.variant === 'link' && props.theme.linkButton?.base};
  ${(props) =>
    props.variant === 'link' &&
    props.color === 'primary' &&
    props.theme.linkButton?.colorPrimary};
  ${(props) =>
    props.variant === 'link' &&
    props.color === 'secondary' &&
    props.theme.linkButton?.colorSecondary};
  ${(props) =>
    props.variant === 'link' &&
    props.color === 'tertiary' &&
    props.theme.linkButton?.colorTertiary};

  ${(props) => props.variant === 'button' && props.theme.button?.base};
  ${(props) =>
    props.variant === 'button' &&
    props.color === 'primary' &&
    props.theme.button?.colorPrimary};
  ${(props) =>
    props.variant === 'button' &&
    props.color === 'secondary' &&
    props.theme.button?.colorSecondary};
  ${(props) =>
    props.variant === 'button' &&
    props.color === 'tertiary' &&
    props.theme.button?.colorTertiary};
  ${(props) =>
    props.variant === 'button' &&
    props.size === 'small' &&
    props.theme.button?.sizeSmall};
  ${(props) =>
    props.variant === 'button' &&
    props.size === 'medium' &&
    props.theme.button?.sizeMedium};
  ${(props) =>
    props.variant === 'button' &&
    props.size === 'large' &&
    props.theme.button?.sizeLarge};
`;

export const LinkButtonStyled = styled(Link)<StyledPropType>`
  display: block;
  text-decoration: none;
  outline: none;

  ${(props) => handleSize(props)}
  ${(props) => handleSpacing(props)};

  button {
    width: 100%;
    height: 100%;
    font-size: inherit;
  }
`;

export const ExternalLinkButtonStyled = styled(Link)<StyledPropType>`
  display: block;
  text-decoration: none;
  outline: none;

  ${(props) => handleSize(props)}
  ${(props) => handleSpacing(props)};

  button {
    width: 100%;
    height: 100%;
    font-size: inherit;
  }
`;
