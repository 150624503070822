import { animations, ThemeAnimations } from './animations';
import { ThemeMixins, mixins } from './mixins';
import {
  FlattenSimpleInterpolation,
  FlattenInterpolation,
  ThemeProps,
  DefaultTheme,
} from 'styled-components';

export interface ThemeColors {
  black: string;
  white: string;
  gray1: string;
  gray2: string;
  gray3: string;
  gray4: string;
  gray5: string;
}

const colors: ThemeColors = {
  black: '#000',
  white: '#fff',
  gray1: '#fafafa',
  gray2: '#eee',
  gray3: '#cccccc',
  gray4: '#666',
  gray5: '#333',
};

export interface Theme {
  colors: ThemeColors;
  findStore?: {
    borderColor: string;
    bgColor: string;
    iconsColor: string;
    fontColor: string;
    selectedStoreBgColor: string;
  };
  colorPhotos?: {
    scrollButtonColor: string;
  };
  button?: {
    base:
      | FlattenSimpleInterpolation
      | FlattenInterpolation<ThemeProps<DefaultTheme>>;
    colorPrimary:
      | FlattenSimpleInterpolation
      | FlattenInterpolation<ThemeProps<DefaultTheme>>;
    colorSecondary:
      | FlattenSimpleInterpolation
      | FlattenInterpolation<ThemeProps<DefaultTheme>>;
    colorTertiary:
      | FlattenSimpleInterpolation
      | FlattenInterpolation<ThemeProps<DefaultTheme>>;
    sizeSmall:
      | FlattenSimpleInterpolation
      | FlattenInterpolation<ThemeProps<DefaultTheme>>;
    sizeMedium:
      | FlattenSimpleInterpolation
      | FlattenInterpolation<ThemeProps<DefaultTheme>>;
    sizeLarge:
      | FlattenSimpleInterpolation
      | FlattenInterpolation<ThemeProps<DefaultTheme>>;
  };
  linkButton?: {
    base:
      | FlattenSimpleInterpolation
      | FlattenInterpolation<ThemeProps<DefaultTheme>>;
    colorPrimary:
      | FlattenSimpleInterpolation
      | FlattenInterpolation<ThemeProps<DefaultTheme>>;
    colorSecondary:
      | FlattenSimpleInterpolation
      | FlattenInterpolation<ThemeProps<DefaultTheme>>;
    colorTertiary:
      | FlattenSimpleInterpolation
      | FlattenInterpolation<ThemeProps<DefaultTheme>>;
  };
  cart?: {
    color: string;
    hoverColor: string;
    badgeColor: string;
  };
  inputSelect?: {
    label:
      | FlattenSimpleInterpolation
      | FlattenInterpolation<ThemeProps<DefaultTheme>>;
    select:
      | FlattenSimpleInterpolation
      | FlattenInterpolation<ThemeProps<DefaultTheme>>;
    error:
      | FlattenSimpleInterpolation
      | FlattenInterpolation<ThemeProps<DefaultTheme>>;
  };
  animations?: ThemeAnimations;
  mixins?: ThemeMixins;
}

export const defaultTheme: Theme = {
  colors,
  findStore: {
    borderColor: colors.gray4,
    bgColor: colors.white,
    iconsColor: colors.gray4,
    fontColor: colors.gray4,
    selectedStoreBgColor: colors.gray3,
  },
  colorPhotos: {
    scrollButtonColor: colors.gray5,
  },
  animations,
  mixins,
};
