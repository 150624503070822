import { config } from "@thisisdevelopment/akzonobel-core";
import IconButton from "components/IconButton";
import { MenuItemLink } from "components/Menu/styled";
import { ContainerInner } from "elements";
import Link from "next/link";
import styled, { css } from "styled-components";
import theme from "theme";

export const TopbarFixed = styled.div<{ hasSubBar?: boolean }>`
  position: fixed;
  height: ${({ hasSubBar }) =>
    hasSubBar ? `${114 + config.subBarHeight}px` : "114px"};
  top: 0;
  left: 0;
  right: 0;
  z-index: 20;

  @media ${theme.mediaQuery.tablet} {
    position: sticky;
    height: 70px;
    background-color: ${({ theme }) => theme.colors.white};
  }
`;

type TopbarStyledPropType = {
  hasBackground: boolean;
  hasBoxShadow: boolean;
};

export const TopbarStyled = styled.div<TopbarStyledPropType>`
  position: relative;
  height: 100%;
  transition: all ease 0.5s;

  svg:hover {
    fill: ${({ theme }) => theme.colors.brandGray200};
  }

  ${({ hasBackground, theme }) =>
    hasBackground
      ? css`
          background-color: rgba(255, 255, 255, 0.95);

          ${MenuItemLink} {
            color: ${theme.colors.brandGold};
          }

          svg {
            fill: ${theme.colors.brandGold};
          }
        `
      : css`
          background-color: transparent;

          ${MenuItemLink} {
            color: ${theme.colors.white};
          }

          svg {
            fill: ${theme.colors.white};
          }
        `};

  ${({ hasBoxShadow }) =>
    hasBoxShadow &&
    css`
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    `};

  @media ${theme.mediaQuery.tablet} {
    svg {
      fill: ${({ theme }) => theme.colors.brandGold};
    }

    ${ContainerInner} {
      margin-left: unset;
      margin-right: unset;
    }
  }
`;

export const LogoLink = styled(Link)`
  background-color: ${({ theme }) => theme.colors.brandBlue};
  height: 101px;
  width: 176px;
  padding: 10px;
  position: relative;
  z-index: 25;

  ${() =>
    config.hasSubBar &&
    css`
      top: -${config.subBarHeight}px;
    `};

  svg {
    width: 100%;
    height: 100%;
  }

  @media ${theme.mediaQuery.tablet} {
    height: 70px;
    width: 120px;
    top: 0;
  }
`;

export const Icons = styled.div`
  display: flex;
  align-items: center;
  height: 70px;
  gap: 10px;
  position: relative;

  svg {
    width: 28px;
    height: 28px;
  }

  @media ${theme.mediaQuery.tablet} {
    margin-right: 15px;
    gap: 15px;

    svg {
      width: 36px;
      height: 36px;
    }
  }
`;

export const TabletOnlyIconButton = styled(IconButton)`
  @media ${theme.mediaQuery.tablet} {
    display: initial;
  }

  display: none;
`;

export const SubBarStyled = styled.div`
  background-color: ${({ theme }) => theme.colors.brandDarkGreen};
  height: ${() => `${config.subBarHeight}px`};

  & div {
    height: 100%;
  }

  @media ${theme.mediaQuery.tablet} {
    display: none;
  }
`;
