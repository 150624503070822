import { Theme, defaultTheme } from '@thisisdevelopment/akzonobel-core';
import { css } from 'styled-components';

const colors = {
  ...defaultTheme.colors,
  brandGold: '#C0AD86',
  brandGray050: '#F0F0F0',
  brandGray100: '#ECECEC',
  brandGray200: '#A3A3A3',
  brandGray400: '#5E5E5E',
  brandGray500: '#3E3D3D',
  brandGray450: '#5C5B5B',
  brandBlue: '  #4A4C59',
  brandBlueDark: '#232F3B',
  brandBlueDarker: '#111F2D',
  brandGoldenGray: '#ADAFA1',
  brandRed: '#50333B',
  brandDarkGreen: '#4c504c26',
  // book colors
  wildBlackberry: '#4E4653',
  oxfordBlue: '#3A4357',
  midnightTeal: '#445A66',
  skyBlue: '#93BEC0',
  sageGreen: '#9EAD9E',
  darkStone: '#C3AD94',
  rawCashmere: '#D4C9B5',
  linnetWhite: '#EBE9E0',
  dustedHeather: '#A99898',
  blossom: '#C79E9C',
  redOchre: '#97534D',
  florentineRed: '#6B2230',
};

export const mediaQuery = {
  mobile: '(max-width: 480px)',
  tablet: '(max-width: 1024px)',
  desktop: '(min-width: 1025px)',
};

export interface ThemeExtended extends Theme {
  colors: typeof colors;
  mediaQuery: typeof mediaQuery;
}

const theme: ThemeExtended = {
  ...defaultTheme,
  mediaQuery,
  colors,
  findStore: {
    borderColor: colors.white,
    bgColor: colors.white,
    iconsColor: colors.brandGold,
    fontColor: colors.brandGold,
    selectedStoreBgColor: colors.brandGray100,
  },
  colorPhotos: {
    scrollButtonColor: colors.brandGold,
  },
  cart: {
    color: colors.brandGold,
    hoverColor: colors.brandGray200,
    badgeColor: colors.brandBlueDark,
  },
  button: {
    base: css`
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 3px;
      transition: all ease 0.2s;
    `,
    colorPrimary: css`
      background-color: ${({ theme }) => theme.colors.brandBlueDark};
      color: ${({ theme }) => theme.colors.brandGold};
      border: 1px solid ${({ theme }) => theme.colors.brandBlueDark};

      &:hover:not(:disabled),
      &:focus:not(:disabled) {
        background-color: ${({ theme }) => theme.colors.brandGold};
        color: ${({ theme }) => theme.colors.brandBlueDark};
        border: 1px solid ${({ theme }) => theme.colors.brandBlueDark};
        cursor: pointer;
      }
    `,
    colorSecondary: css`
      background-color: ${({ theme }) => theme.colors.brandGold};
      color: ${({ theme }) => theme.colors.white};
      border: 1px solid ${({ theme }) => theme.colors.brandGold};

      &:hover:not(:disabled),
      &:focus:not(:disabled) {
        background-color: ${({ theme }) => theme.colors.white};
        color: ${({ theme }) => theme.colors.brandGold};
        border: 1px solid ${({ theme }) => theme.colors.brandGold};
        cursor: pointer;
      }
    `,
    colorTertiary: css`
      background-color: ${({ theme }) => theme.colors.white};
      color: ${({ theme }) => theme.colors.brandGold};
      border: 1px solid ${({ theme }) => theme.colors.brandGold};

      &:hover:not(:disabled),
      &:focus:not(:disabled) {
        background-color: ${({ theme }) => theme.colors.brandGold};
        color: ${({ theme }) => theme.colors.white};
        border: 1px solid ${({ theme }) => theme.colors.white};
        cursor: pointer;
      }
    `,
    sizeSmall: css`
      font-size: 0.8rem;
      padding: 6px;
    `,
    sizeMedium: css`
      font-size: 1rem;
      padding: 8px;
    `,
    sizeLarge: css`
      font-size: 1.2rem;
      padding: 12px;
    `,
  },
  linkButton: {
    base: css`
      background: ${colors.white};
      border: none;

      &:hover:not(:disabled),
      &:focus:not(:disabled) {
        opacity: 0.9;
        cursor: pointer;
      }
    `,
    colorPrimary: css`
      ${(props) => props.theme.mixins?.underlineHover(colors.brandGold)};

      &:hover {
        ${(props) => props.theme.mixins?.underlineHovered};
      }
    `,
    colorSecondary: css`
      ${(props) => props.theme.mixins?.underlineHover(colors.gray4)};

      &:hover {
        ${(props) => props.theme.mixins?.underlineHovered};
      }
    `,
    colorTertiary: css`
      ${(props) => props.theme.mixins?.underlineHover(colors.gray2)};

      &:hover {
        ${(props) => props.theme.mixins?.underlineHovered};
      }
    `,
  },
  inputSelect: {
    select: css`
      padding: 8px;
      background-color: ${({ theme }) => theme.colors.white};
      border-radius: 3px;
      border: 1px solid ${({ theme }) => theme.colors.brandGold};
      color: ${({ theme }) => theme.colors.brandGold};
      font-size: 1rem;

      &:focus {
        outline: 1px solid;
      }
    `,
    label: css`
      color: ${({ theme }) => theme.colors.brandGold};
    `,
    error: css`
      font-size: 0.8rem;
      color: ${({ theme }) => theme.colors.brandRed};
      margin-top: 2px;
    `,
  },
};

export default theme;
