import { config } from '@thisisdevelopment/akzonobel-core';
import { useRouter } from 'next/router';
import { FC } from 'react';
import {
  MenuItem,
  MenuItemLink,
  MenuStyled,
  SubMenuBackground,
  SubMenuItem,
  SubMenuItems,
} from './styled';

type MenuPropType = {
  hasBackground: boolean;
};

type MenuItemType =
  | {
      label: string;
      to: string;
      items?: undefined;
    }
  | {
      label: string;
      items: {
        label: string;
        to: string;
      }[];
      to?: undefined;
    };

const Menu: FC<MenuPropType> = ({ hasBackground }: MenuPropType) => {
  const router = useRouter();
  const pathname = router.asPath;

  const hasActiveChild = (menuItem: MenuItemType) => {
    const links = menuItem.items ? menuItem.items.map(({ to }) => to) : [];
    return links.some((link) => pathname.includes(link));
  };

  return (
    <MenuStyled>
      {config.menuItems &&
        config.menuItems.map((menuItem, menuItemIndex) => (
          <MenuItem
            key={`MenuItem_${menuItemIndex}`}
            hasActiveChild={hasActiveChild(menuItem)}
            hasBackground={hasBackground}
          >
            {menuItem.items ? (
              <>
                <MenuItemLink as="span">{menuItem.label}</MenuItemLink>
                <SubMenuBackground />
                <SubMenuItems hasSubBar={config.hasSubBar}>
                  {menuItem.items.map((subMenuItem, subMenuItemIndex) => (
                    <SubMenuItem
                      $hasBackground={hasBackground}
                      key={`SubMenuItem_${menuItemIndex}_${subMenuItemIndex}`}
                      href={subMenuItem.to}
                    >
                      {subMenuItem.label}
                    </SubMenuItem>
                  ))}
                </SubMenuItems>
              </>
            ) : !menuItem.onlyMobileVisible ? (
              <MenuItemLink href={menuItem.to}>{menuItem.label}</MenuItemLink>
            ) : null}
          </MenuItem>
        ))}
    </MenuStyled>
  );
};

export default Menu;
