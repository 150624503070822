import { useCallback, useEffect, useRef } from 'react';

export default function useIntersectionOberserver<T>(callback: () => void) {
  const ref = useRef<T>(null);

  const internalCallback = useCallback(
    (entries: any) => {
      if (entries[0].isIntersecting) {
        callback();
      }
    },
    [callback]
  );

  useEffect(() => {
    if (ref.current) {
      const observer = new IntersectionObserver(internalCallback);
      const element = ref.current as any;
      observer.observe(element);

      return () => observer.unobserve(element);
    }
  }, [ref, internalCallback]);

  return { ref };
}
