import React, { useRef, useEffect, useState } from 'react';
import { Color, Svg } from '@thisisdevelopment/akzonobel-core';
import ColorCard from '../ColorCard';
import { ColorPickerStyled, ScrollLeft, ScrollRight, Colors, ScrollArea } from './styled';

type PropTypes = {
  colors: Color[];
};

function ColorWall({ colors }: PropTypes) {
  const containerRef = useRef<HTMLDivElement>(null);
  const scrollerRef = useRef<HTMLDivElement>(null);
  const [hasMoreLeft, setHasMoreLeft] = useState(false);
  const [hasMoreRight, setHasMoreRight] = useState(true);
  const [scrollingLeft, setScrollingLeft] = useState(false);
  const [scrollingRight, setScrollingRight] = useState(false);
  const tick = useRef<any>();
  const threshold = 120;
  const interval = 15;
  // Increasing the step will make the scroll jumpy, decreasing will make it not work when zoomed out
  const step = 15;

  const handleClick = () => {
    return false;
  };

  useEffect(() => {
    const listener = () => {
      if (containerRef.current && scrollerRef.current) {
        const { offsetWidth } = containerRef.current;
        const { scrollLeft, scrollWidth } = scrollerRef.current;

        setHasMoreLeft(scrollLeft !== 0);
        setHasMoreRight(scrollLeft < scrollWidth - offsetWidth - step);
      }
    };
    const scrollerElement = scrollerRef.current;

    scrollerElement?.addEventListener('scroll', listener);

    return () => {
      scrollerElement?.removeEventListener('scroll', listener);
    };
  }, []);

  useEffect(() => {
    const listener = (e: MouseEvent) => {
      if (containerRef.current && (containerRef.current as any).contains(e.target)) {
        const mouseX = e.clientX - containerRef.current.offsetLeft;
        const { offsetWidth } = containerRef.current;

        setScrollingLeft(mouseX < threshold);
        setScrollingRight(mouseX > offsetWidth - threshold);
      } else {
        setScrollingLeft(false);
        setScrollingRight(false);
      }
    };

    document.addEventListener('mousemove', listener);

    return () => {
      document.removeEventListener('mousemove', listener);
    };
  }, [scrollerRef, containerRef, setScrollingLeft, setScrollingRight]);

  useEffect(() => {
    if (scrollingRight) {
      tick.current = setInterval(() => {
        scrollerRef.current?.scrollBy({ left: step });
      }, interval);
    } else if (scrollingLeft) {
      tick.current = setInterval(() => {
        scrollerRef.current?.scrollBy({ left: -step });
      }, interval);
    } else {
      clearInterval(tick.current);
    }
  }, [scrollingRight, scrollingLeft]);

  return (
    <ColorPickerStyled ref={containerRef}>
      {hasMoreLeft && (
        <ScrollLeft onClick={() => handleClick()}>
          <Svg.Carret />
        </ScrollLeft>
      )}
      <ScrollArea ref={scrollerRef}>
        {/* <SectionTitle>First Rows</SectionTitle> */}
        <Colors>
          {colors
            // .filter((color) => color.colorWall && color.colorWall.row <= 3)
            .map((color) => (
              <ColorCard key={color.colorCollectionColorId} color={color} mode={'Color'} />
            ))}
        </Colors>
      </ScrollArea>
      {hasMoreRight && (
        <ScrollRight onClick={() => handleClick()}>
          <Svg.Carret />
        </ScrollRight>
      )}
    </ColorPickerStyled>
  );
}

export default ColorWall;
