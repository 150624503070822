import {
  StyledImagePropType,
  useCmsImage,
} from '@thisisdevelopment/akzonobel-core';
import React, { useEffect } from 'react';
import { StyledImage } from './styled';

type PropTypes = {
  id: number;
  alt: string;
  priority?: boolean;
};

const CmsImage = React.forwardRef(
  ({ id, ...rest }: PropTypes & StyledImagePropType, ref) => {
    const { file, fetchImage } = useCmsImage();

    useEffect(() => {
      fetchImage(id);
    }, [fetchImage, id]);

    let img = {
      src: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAACCAYAAABytg0kAAAAFElEQVQIW2MMDQ39z8DAwMAIYwAAKgMD/9AXrvgAAAAASUVORK5CYII=',
      width: 1,
      height: 1,
    };
    if (file) {
      img = { src: file.data.full_url, width: file.width, height: file.height };
    }

    // @ts-expect-error
    return <StyledImage {...img} {...rest} {...ref} />;
  }
);

export default CmsImage;
