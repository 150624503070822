import { useCallback, useContext, useState } from 'react';
import { ActionTypes, EcomContext } from '../contexts/ecom';
import api from '../services/ecom';
import {
  BatchSkuType,
  BatchStockInfoType,
  Cart,
  StockInfo,
} from '../types/ecom';
import useNotifications from './useNotifications';

export default function useEcom(): {
  cart?: Cart;
  batchStockInfo?: BatchStockInfoType[];
  hasStock?: boolean;
  stockInfo: StockInfo | undefined;
  isLoading: boolean;
  updateCart: () => Promise<void>;
  addToCart: (
    articleNumber: number,
    cccid: number | null,
    quantity?: number,
    referrer?: string
  ) => Promise<void>;
  addBatchToCart: (skus: BatchSkuType) => Promise<void>;
  checkStock: (articleNumber: number, cccid: number | null) => Promise<void>;
  checkBatchStock: (skus: BatchSkuType, batchId: string) => Promise<void>;
  resetStockCheck: () => void;
} {
  const [state, dispatch] = useContext(EcomContext);
  const { show } = useNotifications();
  const [hasStock, setHasStock] = useState<boolean | undefined>();
  const [stockInfo, setStockInfo] = useState<StockInfo | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { cart, batchStockInfo } = state;

  const updateCart = useCallback(async () => {
    try {
      setIsLoading(true);
      const { data: cart } = await api.getCartDetails();
      dispatch({ type: ActionTypes.UpdateCart, payload: { cart } });
    } finally {
      setIsLoading(false);
    }
  }, [dispatch]);

  const addToCart = useCallback(
    async (
      articleNumber: number,
      cccid: number | null,
      quantity = 1,
      referrer = ''
    ) => {
      try {
        setIsLoading(true);
        await api.addToCart(articleNumber, cccid, quantity, referrer);
        await updateCart();
        show('success', 'The product was added successfully to the cart.');
      } finally {
        setIsLoading(false);
      }
    },
    [updateCart, show]
  );

  const addBatchToCart = useCallback(
    async (skus: BatchSkuType) => {
      try {
        setIsLoading(true);
        await api.addBatchToCart(skus);
        await updateCart();
        show('success', 'The products were added successfully to the cart.');
      } finally {
        setIsLoading(false);
      }
    },
    [updateCart, show]
  );

  const checkStock = useCallback(
    async (articleNumber: number, cccid: number | null) => {
      try {
        setIsLoading(true);
        const resp = await api.checkStock(articleNumber, cccid);
        setHasStock(resp.data.enabled);
        if (resp.data.indicator) {
          setStockInfo({
            shortMessage: resp.data.shortMessage,
            indicator: resp.data.indicator,
          });
        }
      } catch (error) {
        setIsLoading(false);
        setHasStock(false);
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    },
    [setHasStock]
  );

  const checkBatchStock = useCallback(
    async (skus: BatchSkuType, batchId: string) => {
      try {
        setIsLoading(true);
        const resp = await api.checkBatchStock(skus);
        dispatch({
          type: ActionTypes.UpdateBatchStockInfo,
          payload: {
            batchStockInfo: { stockInfo: resp.data, batchId: batchId },
          },
        });
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch]
  );

  const resetStockCheck = useCallback(() => {
    setHasStock(undefined);
    setStockInfo(undefined);
  }, [setHasStock]);

  return {
    cart,
    batchStockInfo,
    hasStock,
    stockInfo,
    isLoading,
    checkStock,
    checkBatchStock,
    addToCart,
    addBatchToCart,
    updateCart,
    resetStockCheck,
  };
}
