import { useState, useCallback } from 'react';
import { AxiosError } from 'axios';
import { Product } from '../types';
import api from '../services/bff';

type ProductKey = {
  globalId?: string;
  friendlyName?: string;
};

type UseProductType = {
  isLoading: boolean;
  hasJustLoaded: boolean;
  product: Product | undefined;
  setProduct: (product: Product | undefined) => void;
  fetchProduct: (key: ProductKey, query?: string) => void;
  error: AxiosError | Error | undefined;
};

export default function useProduct(): UseProductType {
  const [product, setProduct] = useState<Product | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasJustLoaded, setHasJustLoaded] = useState<boolean>(false);
  const [error, setError] = useState<AxiosError | Error | undefined>();

  const fetchProduct = useCallback(async (key: ProductKey, query?: string) => {
    const fetch = async () => {
      try {
        setIsLoading(true);
        const resp = key.globalId
          ? await api.getProductByGlobalId(key.globalId, query)
          : await api.getProductByFriendlyName(key.friendlyName || '', query);
        setProduct(resp.data);
        setIsLoading(false);
        setHasJustLoaded(true);
        setTimeout(() => setHasJustLoaded(false), 1000);
        setError(undefined);
      } catch (error) {
        setProduct(undefined);
        setHasJustLoaded(false);
        setIsLoading(false);
        setError(error as AxiosError | Error);
      }
    };
    await fetch();
  }, []);

  return {
    isLoading,
    hasJustLoaded,
    product,
    setProduct,
    fetchProduct,
    error,
  };
}
