import { css } from 'styled-components';

export type StyledFlexPropType = {
  alignItems?: 'flex-start' | 'flex-end' | 'center' | 'baseline' | 'stretch';
  justifyContent?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly';
  flex?: string;
  flexWrap?: 'nowrap' | 'wrap' | 'wrap-reverse';
  gap?: string;
};

export const handleFlex = (props: StyledFlexPropType) => css`
  flex: ${props.flex};
  justify-content: ${props.justifyContent};
  align-items: ${props.alignItems};
  flex-wrap: ${props.flexWrap};
  gap: ${props.gap};
`;
