import React, { FC } from 'react';
import { H2, StyledSpacingPropType } from '@thisisdevelopment/akzonobel-core';
import CmsCallToAction, { CmsCallToActionType } from 'components/CmsCallToAction';
import { CallToActionsStyled, CallToActionsWrapper } from './styled';

type PropType = {
  title: string;
  items: CmsCallToActionType[];
};

const CmsCallToActions: FC<PropType & StyledSpacingPropType> = ({
  items,
  title,
  ...rest
}: PropType & StyledSpacingPropType) => {
  return (
    <CallToActionsStyled {...rest}>
      <H2>{title}</H2>
      <CallToActionsWrapper>
        {items.map((item, index) => (
          <CmsCallToAction key={`CallToAction_${index}`} callToAction={item} />
        ))}
      </CallToActionsWrapper>
    </CallToActionsStyled>
  );
};

export default CmsCallToActions;
