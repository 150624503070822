import useContextReducer, { Action } from '../hooks/useContextReducer';

export type NotificationType = 'success' | 'warning';

export type Notification = {
  timestamp: number;
  message: string;
  type: NotificationType;
};

type State = {
  notifications: Notification[];
};

export enum ActionTypes {
  Show = 'NOTIFICATIONS/SHOW',
  Clear = 'NOTIFICATIONS/CLEAR',
}

const initialState: State = { notifications: [] };

const reducer = (state: State, action: Action<ActionTypes>) => {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.Show:
      return { ...state, notifications: [...state.notifications, payload.notification] };
    case ActionTypes.Clear:
      return {
        ...state,
        notifications: state.notifications.filter(
          ({ timestamp }) => timestamp !== payload.timestamp
        ),
      };
    default:
      return state;
  }
};

const { Provider, Context } = useContextReducer<State, Action<ActionTypes>>(initialState, reducer);

export { Provider as NotificationsContextProvider, Context as NotificationsContext };
