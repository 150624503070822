import React, { FC } from 'react';
import { validation, useForm, Button } from '@thisisdevelopment/akzonobel-core';
import InputField from 'components/InputField';
import { TextBlock } from './styled';

const initialValues = {
  email: '',
};

const formValidation = {
  email: [validation.required, validation.email],
};

type FormType = typeof initialValues;

type PropType = {
  onSuccess: (values: FormType) => void;
};

const SignInFirstStep: FC<PropType> = ({ onSuccess }: PropType) => {
  const { bindInput, handleSubmit } = useForm(initialValues, formValidation);

  const onContinue = () =>
    handleSubmit((values: FormType) => {
      onSuccess(values);
    });

  return (
    <>
      <TextBlock>Fill in your email address to login or create a new account</TextBlock>
      <InputField
        placeholder="EMAIL ADDRESS"
        mb="20px"
        type="email"
        autoFocus
        {...bindInput('email')}
      />
      <Button ml="auto" color="secondary" size="medium" onClick={onContinue} width="150px">
        CONTINUE
      </Button>
    </>
  );
};

export default SignInFirstStep;
