import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  html {
    scroll-behavior: smooth;
    font-family: 'Source Serif Pro', serif;
    font-size: 16px;
    letter-spacing: 0.2px;
  }

  input, button, select, textarea {
    font-family: 'Source Sans Pro', sans-serif;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: 'Source Sans Pro', sans-serif;
  }

  body {
    margin: 0;
    overflow-y: scroll;
    background-color: ${(props) => props.theme.colors.brandGray050};
  }
`;

export default GlobalStyle;
