import styled, { css } from 'styled-components';
import { ButtonElement } from '@thisisdevelopment/akzonobel-core';
import { mediaQuery } from 'theme';

export const ColorPickerStyled = styled.section<{ collapsed: boolean }>`
  padding: 30px;
  display: ${({collapsed}) => collapsed ? 'none' : 'block'};
  @media ${mediaQuery.mobile} {
    padding: 15px;
  }
`;

export const Groups = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  gap: 30px;

  @media ${mediaQuery.mobile} {
    justify-content: flex-start;
    gap: 10px;
  }
`;

export const Group = styled.li`
  margin: 0;
  padding: 0;
  color: ${({ theme }) => theme.colors.brandGold};
`;

export const GroupButton = styled(ButtonElement)<{ isActive: boolean }>`
  background-color: transparent;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.brandGold};
  font-size: 0.9rem;
  font-weight: 600;
  padding: 0;

  &:hover {
    cursor: pointer;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      color: ${({ theme }) => theme.colors.brandGray200};
    `}

  @media ${mediaQuery.mobile} {
    font-size: 0.8rem;
  }
`;

export const SelectedGroup = styled.div`
  h2 {
    color: ${({ theme }) => theme.colors.brandGold};
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 600;
    margin: 15px 0;
  }
`;

export const ColorsContainer = styled.div<{selected: boolean}>`
  flex: 1;
  display: ${({selected}) => selected ? 'grid' : 'none'};
  grid-template-columns: repeat(auto-fill, 110px);
  grid-auto-rows: 65px;
  gap: 10px;
  
  @media ${mediaQuery.mobile} {
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  }
`;
