import React, { FC, useState } from 'react';
import {
  useClickOutside,
  H1,
  H2,
  Button,
  validation,
  useForm,
  bffService,
} from '@thisisdevelopment/akzonobel-core';
import InputField from './../InputField';
import { StyledPropType, ModalStyled, Header, ModalContainer, ButtonContainer } from './styled';
import form from './form';

type PropType = {
  title?: string;
  subTitle?: string;
  successText?: string;
  onClose: () => void;
};

const OrderFormModal: FC<PropType & StyledPropType> = ({
  title,
  subTitle,
  successText,
  onClose,
  width,
}: PropType & StyledPropType) => {
  const { ref } = useClickOutside<HTMLDivElement>(onClose);
  const [submitted, setSubmitted] = useState(false);

  const initialValues = {
    name: '',
    email: '',
    address: '',
    eircode: '',
    town: '',
    telephone: '',
  };

  const formValidation = {
    name: [validation.required],
    email: [validation.required, validation.email],
    address: [validation.required],
    eircode: [validation.required],
    town: [validation.required],
  };

  const { bindInput, handleSubmit } = useForm(initialValues, formValidation);

  const sendContact = async (values: any) => {
    const payload = {
      ...form,
      formData: form.formData.map((field) => ({ ...field, values: [values[field.id]] })),
    };
    await bffService.sendContact(payload);
    setSubmitted(true);
  };

  return submitted ? (
    <ModalContainer>
      <ModalStyled ref={ref} width={width}>
        {(title || successText) && (
          <Header>
            {title && <H1>{title}</H1>}
            {successText && <H2>{successText}</H2>}
          </Header>
        )}
        <ButtonContainer>
          <Button color="secondary" width="200px" onClick={() => onClose()}>
            Close
          </Button>
        </ButtonContainer>
      </ModalStyled>
    </ModalContainer>
  ) : (
    <ModalContainer>
      <ModalStyled ref={ref} width={width}>
        {(title || subTitle) && (
          <Header>
            {title && <H1>{title}</H1>}
            {subTitle && <H2>{subTitle}</H2>}
          </Header>
        )}
        <InputField label="Name" mb="20px" {...bindInput('name')} autoFocus />
        <InputField label="Email" mb="20px" {...bindInput('email')} />
        <InputField label="Address" mb="20px" {...bindInput('address')} />
        <InputField label="Eircode" mb="20px" {...bindInput('eircode')} />
        <InputField label="Town" mb="20px" {...bindInput('town')} />
        <InputField label="Phone number" mb="20px" {...bindInput('telephone')} />
        <ButtonContainer>
          <Button color="secondary" width="200px" onClick={() => onClose()}>
            Cancel
          </Button>
          <Button color="secondary" width="200px" onClick={() => handleSubmit(sendContact)}>
            Send
          </Button>
        </ButtonContainer>
      </ModalStyled>
    </ModalContainer>
  );
};

export default OrderFormModal;
