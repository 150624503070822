import axios, {
  AxiosError,
  AxiosInstance,
  InternalAxiosRequestConfig,
} from 'axios';
import Cookies from 'universal-cookie';
import config from '../../config';

type Token = {
  token: string;
  expire: string;
};

let isRefreshingToken = false;
const processQueue: Array<(token: Token) => any> = [];

const cookies = new Cookies();

const axiosInstance: AxiosInstance = axios.create();

export function deleteTokenLocalStore(): void {
  cookies.remove('apiToken');
  //localStorage.removeItem('token');
}

function setTokenLocalStore(token: Token): void {
  cookies.set('apiToken', token);
  //localStorage.setItem('token', JSON.stringify(token));
}

function getTokenLocalStore(): Token {
  const stringToken = cookies.get('apiToken');
  //const stringToken = localStorage.getItem('token');
  return stringToken ?? null;
}

function getNewToken() {
  const { domainCode, language, channel } = config;
  return axios.post(`${axiosInstance.defaults.baseURL}/token/new`, {
    domainCode,
    language,
    channel,
  });
}

function isTokenExpiringOrExpired(token: Token) {
  return new Date(token.expire).getTime() - new Date().getTime() < 60000;
}

function processQueueWithNewToken(token: Token): void {
  processQueue.forEach((fn) => fn(token));
  processQueue.splice(0);
}

async function getToken(): Promise<Token> {
  const token = getTokenLocalStore();
  if (token && !isTokenExpiringOrExpired(token)) {
    return token;
  }

  isRefreshingToken = true;
  const { data: newToken } = await getNewToken();
  setTokenLocalStore(newToken);
  isRefreshingToken = false;

  processQueueWithNewToken(newToken);

  return newToken;
}

function setHeaderToken(config: InternalAxiosRequestConfig, token: Token) {
  config.headers['Authorization'] = `Bearer ${token.token}`;
  return config;
}

function waitForTokenRefresh(config: InternalAxiosRequestConfig<any>) {
  return new Promise<InternalAxiosRequestConfig<any>>((resolve) => {
    processQueue.push((token: Token) => resolve(setHeaderToken(config, token)));
  });
}

export function initAxiosConfig(baseURL: string): void {
  axiosInstance.defaults.baseURL = baseURL;

  axiosInstance.interceptors.request.use(
    async (config) => {
      if (isRefreshingToken) {
        return waitForTokenRefresh(config);
      }

      const token = await getToken();
      return setHeaderToken(config, token);
    },
    (error: AxiosError) => {
      return Promise.reject(error);
    }
  );
}

export default axiosInstance;
