import styled from 'styled-components';
import theme from 'theme';
import { FlexCol } from '@thisisdevelopment/akzonobel-core';
import Link from 'next/link';

export const FooterStyled = styled.footer``;

export const Links = styled.div`
  background-color: ${({ theme }) => theme.colors.brandGold};
`;

export const LinksContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 40px;
  padding: 40px 0;

  @media ${theme.mediaQuery.tablet} {
    grid-template-columns: 1fr 1fr;
    padding: 40px;
  }

  @media ${theme.mediaQuery.mobile} {
    grid-template-columns: 1fr;
    padding: 40px 0;
  }

  h3 {
    color: ${({ theme }) => theme.colors.white};
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 15px;
  }

  a {
    color: ${({ theme }) => theme.colors.brandBlue};
    text-decoration: none;
    line-height: 1.5em;

    &:hover {
      text-decoration: underline;
    }
  }

  p {
    color: ${({ theme }) => theme.colors.brandBlue};
  }

  button {
    border-radius: 5px;
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.brandGray400};
    font-family: 'Source Sans Pro', sans-serif;
    width: 120px;
    padding: 5px;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 0.9rem;
    transition: all ease 0.3s;

    &:hover {
      cursor: pointer;
      background-color: ${({ theme }) => theme.colors.brandGray400};
      color: ${({ theme }) => theme.colors.white};
    }
  }
`;

export const Bottom = styled.div`
  background-color: ${({ theme }) => theme.colors.brandBlue};
  color: ${({ theme }) => theme.colors.white};
  padding: 40px 0;
  font-weight: 600;

  @media ${theme.mediaQuery.tablet} {
    padding: 40px;
  }

  @media ${theme.mediaQuery.mobile} {
    padding: 20px 0;
  }
`;

export const Social = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 20px;

  svg {
    width: 40px;
    height: 40px;
    fill: ${({ theme }) => theme.colors.white};
    transition: all ease 0.3s;

    &:hover {
      fill: ${({ theme }) => theme.colors.brandGray400};
    }
  }
`;

export const SocialWrapper = styled(FlexCol)`
  height: 100%;
  gap: 16px;
`;

export const NewsLetterLink = styled(Link)`
  font-size: 1.1rem;
  color: ${({ theme }) => theme.colors.white} !important;
  text-decoration: underline !important;

  &:hover {
    color: ${({ theme }) => theme.colors.brandGray400} !important;
  }
`;
