import React from 'react';
import { HueColor } from '../../types';
import Svg from '../Svg';
import { getColorNameByBgColorHex } from '../../util';
import { HueColorPickerStyled, ColorHue } from './styled';

type PropTypes = {
  hueColors: HueColor[];
  selected?: string | null;
  onSelect?: Function;
};

function HueColorPicker({ hueColors, onSelect, selected }: PropTypes) {
  return (
    <HueColorPickerStyled>
      {hueColors.map((hueColor) => {
        const isSelected = selected === hueColor.hueName[0].global;
        return (
          <ColorHue
            key={hueColor.rgb}
            isSelected={isSelected}
            style={{ backgroundColor: `#${hueColor.rgb}` }}
            onClick={() => onSelect?.(hueColor.hueName[0].global)}
          >
            {isSelected && <Svg.Check fill={getColorNameByBgColorHex(hueColor.rgb)} width="32px" />}
          </ColorHue>
        );
      })}
    </HueColorPickerStyled>
  );
}

export default HueColorPicker;
