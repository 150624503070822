import { useState, useCallback } from 'react';
import { AxiosError } from 'axios';
import api from '../services/bff';

type CmsItemOptions = {
  single?: boolean;
  ignoreStatus?: boolean;
};

export default function useCmsItem<T>(
  collection: string,
  options?: CmsItemOptions
): [
  T | undefined,
  (id?: number) => void,
  boolean,
  AxiosError | Error | undefined
] {
  const [items, setItems] = useState<T | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<AxiosError | Error | undefined>();

  const onlyPublished = ({ status }: { status: string }) =>
    options?.ignoreStatus || status === 'published';

  const fetchItem = useCallback(async (id?: number) => {
    const fetch = async () => {
      try {
        setIsLoading(true);

        if (id) {
          const resp = await api.getCmsItemById(collection, id);
          setItems(resp.data.data);
        } else {
          const resp = await api.getCmsItem(collection);
          setItems(
            options?.single
              ? resp.data.data.find(onlyPublished)
              : resp.data.data.filter(onlyPublished)
          );
        }

        setIsLoading(false);
      } catch (error) {
        setItems(undefined);
        setIsLoading(false);
        setError(error as Error | AxiosError);
      }
    };
    await fetch();
  }, []);

  return [items, fetchItem, isLoading, error];
}
