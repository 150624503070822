import styled from 'styled-components';
import { Label } from '@thisisdevelopment/akzonobel-core';

export type InputStyledPropType = {
  height?: string;
};

export const LabelStyled = styled(Label)`
  margin-bottom: 5px;
`;

export const InputStyled = styled.input<InputStyledPropType>`
  padding: 8px 12px;
  border: 1px solid;
  color: inherit;
  font-size: 1rem;
  height: ${({ height }) => height};
  resize: none;

  &::placeholder {
    color: inherit;
    opacity: 0.5;
  }

  &:focus {
    outline: 1px solid;
  }
`;

export const ErrorStyled = styled.span`
  font-size: 0.8rem;
  color: ${({ theme }) => theme.colors.brandRed};
  margin-top: 2px;
  font-family: 'Source Sans Pro', sans-serif;
`;
