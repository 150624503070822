import styled, { css } from 'styled-components';
import { ButtonElement } from '@thisisdevelopment/akzonobel-core';
import theme from 'theme';

export type StyledPropTypes = {
  hasBackground?: boolean;
};

export const ButtonStyled = styled(ButtonElement)<StyledPropTypes>`
  background-color: transparent;
  padding: 5px;
  transition: all ease 0.3s;
  display: flex;
  align-items: center;

  @media ${theme.mediaQuery.tablet} {
    padding: 0;
    border: none;
  }

  svg {
    transition: all ease 0.3s;
    width: 25px;
    height: 25px;
  }

  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.brandGray200 ?? '#fff'};
    border-color: ${({ theme }) => theme.colors.brandGray200 ?? '#fff'};
    background-color: #fff;

    svg {
      fill: ${({ theme }) => theme.colors.brandGray200 ?? '#fff'};
    }
  }

  ${({ hasBackground }) =>
    hasBackground
      ? css`
          color: ${({ theme }) => theme.colors.brandGold};
          border-color: ${({ theme }) => theme.colors.brandGold};

          &:hover {
            color: ${({ theme }) => theme.colors.brandGray200};
            border-color: ${({ theme }) => theme.colors.brandGray200};
          }
        `
      : css`
          color: ${({ theme }) => theme.colors.white};
          border-color: ${({ theme }) => theme.colors.white};

          &:hover {
            color: ${({ theme }) => theme.colors.brandGray200};
            border-color: ${({ theme }) => theme.colors.brandGray200};
          }
        `}
`;

export const LinkText = styled.span`
  padding: 0 5px;
  display: flex;
  align-items: center;
  text-align: center;
  font-family: 'Source Sans Pro', sans-serif;
  text-decoration: none;
  text-transform: capitalize;
  font-weight: 600;
  font-size: 16px;

  @media ${theme.mediaQuery.tablet} {
    display: none;
  }

  display: initial;
`;
