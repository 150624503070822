import { shouldForwardProp } from '@thisisdevelopment/akzonobel-core';
import Link from 'next/link';
import styled from 'styled-components';
import theme, { mediaQuery } from 'theme';

export const Colors = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(1fr, 120px));
  grid-auto-rows: 75px;
  gap: 1px;

  > :first-child {
    font-family: 'Open Sans', sans-serif;
    justify-self: center;
    font-weight: 700;
    grid-column: 1 / 3;
    grid-row: 1;
    margin-top: 5px;

    @media ${theme.mediaQuery.mobile} {
      margin-top: 15px;
    }
  }

  > :nth-child(2) {
    grid-column: 1 / 3;
    grid-row: 3;
    pointer-events: none;

    span {
      display: none;
    }
  }
`;

type ColorBlockStyledPropType = {
  bgColor: string;
};

export const ColorBlockStyled = styled(
  Link
).withConfig<ColorBlockStyledPropType>({
  shouldForwardProp,
})`
  position: relative;
  transition: all ease 0.2s;
  background-color: ${({ bgColor }) => bgColor};

  span {
    font-family: 'Open Sans', sans-serif;
    font-size: 0.8rem;
    color: ${({ theme }) => theme.colors.black};
    position: absolute;
    top: -20px;
    left: 5px;
    width: calc(100% - 5px);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &:hover {
    transform: scale(1.05);
    z-index: 2;
  }
`;

export const ColorCombinationsStyled = styled.div`
  padding: 70px 0;
  display: flex;
  gap: 50px;

  h2 {
    width: 250px;
    font-size: 1.5rem;
    text-transform: uppercase;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.brandGold};
  }

  @media ${mediaQuery.tablet} {
    flex-direction: column;
    padding: 40px 0 70px 0;
    gap: 30px;

    h2 {
      width: unset;
      font-size: 1.2rem;
    }
  }
`;

export const Blocks = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;

  @media ${theme.mediaQuery.mobile} {
    grid-template-columns: 1fr;
    gap: 50px;
  }
`;
