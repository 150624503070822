import { useState, useCallback } from 'react';
import api from '../services/bff';

export default function useCmsPage() {
  const [file, setFile]: [any, Function] = useState();

  const fetchFile = useCallback(async (id: number) => {
    const fetch = async () => {
      const resp = await api.getCmsFile(id);
      setFile(resp.data.data);
    };
    await fetch();
  }, []);

  return {
    file,
    fetchFile,
  };
}
