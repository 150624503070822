import { Product, Color, Article } from '@thisisdevelopment/akzonobel-core';

type TagManagerTypes = {
  product: Product;
  article?: Article;
  color?: Color;
  quantity?: number;
};

export const getArgsProductImpressions = (products: Product[]) => {
  return {
    dataLayer: {
      event: 'productImpression',
      ecommerce: {
        currencyCode: 'GBP',
        impressions: products.map((product) => {
          return {
            name: product.name,
            id: product.globalId,
            brand: product.brandName,
            category: product.productType,
            list: 'Products',
            quantity: 1,
          };
        }),
      },
    },
  };
};

export const getArgsProduct = ({ product }: TagManagerTypes) => {
  return {
    dataLayer: {
      event: 'productView',
      ecommerce: {
        detail: {
          actionField: { list: 'Product list' },
          products: [
            {
              name: product.name,
              id: product.globalId,
              brand: product.brandName,
              category: product.productType,
            },
          ],
        },
      },
    },
  };
};

export const getArgsCartAdd = ({ product, article, color, quantity }: TagManagerTypes) => {
  return {
    dataLayer: {
      event: 'addToCart',
      ecommerce: {
        currencyCode: 'GBP',
        add: {
          products: [
            {
              name: product.name,
              id: product.globalId,
              price: article?.price.price,
              brand: product.brandName,
              category: product.productType,
              variant: color ? color.defaultColorName : undefined,
              quantity: quantity,
            },
          ],
        },
      },
    },
  };
};

export const getArgsProductClick = (product: Product, withColorPicker: boolean) => {
  return {
    dataLayer: {
      event: 'productClick',
      ecommerce: {
        click: {
          actionField: { list: 'Product list' },
          products: [
            {
              name: product.name,
              id: product.globalId,
              brand: product.brandName,
              category: product.productType,
            },
          ],
        },
      },
      // eventCallback: function () {
      //   document.location.href = `/en/products/details/${product.uriFriendlyName}${
      //     withColorPicker ? `?pickAColour=true` : ''
      //   }`;
      // },
    },
  };
};
