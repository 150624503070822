import styled from 'styled-components';
import { Paragraph, H1 } from '@thisisdevelopment/akzonobel-core';

export const Title = styled(H1)`
  text-align: center;
  margin: 20px 0;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 2.2rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.brandGray450};
`;

export const TextBlock = styled(Paragraph)`
  text-align: center;
  margin: 30px 0;
  font-family: 'Source Sans Pro', sans-serif;
  color: ${({ theme }) => theme.colors.brandGray450};
`;
