import React from 'react';
import { useRouter } from 'next/router';
import { FlexRow, config, Svg } from '@thisisdevelopment/akzonobel-core';
import { ContainerInner } from 'elements/index';
import OutlinedButton from 'components/OutlinedButton';
import theme from 'theme';
import { SubBarStyled } from './styled';

function SubBar({ hasBackground }: { hasBackground: boolean }) {
  const router = useRouter();

  if (!config.hasSubBar) {
    return null;
  }

  return (
    <SubBarStyled>
      <ContainerInner>
        <FlexRow justifyContent="flex-end" gap="20px">
          <OutlinedButton
            hasBackground={hasBackground}
            onClick={() => router.push('/en/stores')}
            label="Find a Stockist"
            icon={<Svg.MarkerEmpty fill={theme.findStore?.bgColor} />}
          />
          <OutlinedButton
            hasBackground={hasBackground}
            onClick={() =>
              router.push('/en/products/details/dulux-heritage-colour-card')
            }
            label="Order your free colour card"
            icon={<Svg.ColorCardIcon />}
          />
        </FlexRow>
      </ContainerInner>
    </SubBarStyled>
  );
}

export default SubBar;
