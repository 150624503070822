import { useState, useCallback } from 'react';
import { SearchResult, PaginationInfo, SearchParams } from '../types';
import api from '../services/bff';

type SearchType = {
  results: SearchResult[];
  fetchResults: (searchParams: SearchParams) => Promise<void>;
  isLoading: boolean;
  hasResults: boolean;
  pagination?: PaginationInfo;
};

export default function useSearch(): SearchType {
  const [results, setResults] = useState<SearchResult[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasResults, setHasResults] = useState<boolean>(false);
  const [pagination, setPagination] = useState<PaginationInfo | undefined>();

  const fetchResults = useCallback(async (searchParams: SearchParams) => {
    const fetch = async () => {
      try {
        setIsLoading(true);
        const resp = await api.getSearch(searchParams);
        setResults(resp.data.results);
        setPagination(resp.data.pagination);
        setHasResults(resp.data.results.length > 0);
        setIsLoading(false);
      } catch (error) {
        setResults([]);
        setHasResults(false);
        setIsLoading(false);
      }
    };
    await fetch();
  }, []);

  return { results, fetchResults, pagination, isLoading, hasResults };
}
