import theme from 'theme';
import styled from 'styled-components';
import { Spacer } from '@thisisdevelopment/akzonobel-core';

export const RelatedArticlesStyled = styled(Spacer)`
  background-color: ${({ theme }) => theme.colors.brandGold};
  color: ${({ theme }) => theme.colors.white};
  padding: 30px 160px 50px 160px;

  h2 {
    font-size: 1.5rem;
    font-weight: 600;
    text-align: center;
    margin-bottom: 30px;
    text-transform: uppercase;
  }

  @media ${theme.mediaQuery.tablet} {
    padding: 30px;

    h2 {
      font-size: 1.1rem;
    }
  }

  @media ${theme.mediaQuery.mobile} {
    padding: 30px;
  }
`;

export const RelatedArticlesWrapper = styled.section`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 250px;
  gap: 20px;

  @media ${theme.mediaQuery.mobile} {
    grid-template-columns: 1fr;
  }
`;
