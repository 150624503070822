import React from "react"

const Carret = ({ ...props }) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path fillRule="evenodd" d="M28.3 8.4c-.8-.7-2.1-.7-2.8.2L16 19.3 6.5 8.6c-.7-.8-2-.9-2.8-.2-.8.7-.9 2-.2 2.8l10.8 12.2c.4.4.9.7 1.5.7h.4c.6 0 1.1-.2 1.5-.7l10.8-12.2c.7-.8.7-2-.2-2.8" clipRule="evenodd"></path></svg>
  )
}

export default Carret

