import styled from 'styled-components';
import { ColorCardStyled, ColorName, ColorNameIn } from '../ColorCard/styled';

export const ColorPickerStyled = styled.div`
  position: relative;
  min-height: 500px;

  @media (max-width: 768px) {
    min-height: unset;
  }
`;

export const ScrollArea = styled.div`
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 768px) {
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
  }
`;

export const Colors = styled.div`
  display: grid;
  grid-template-columns: repeat(16, 120px);
  grid-auto-rows: 80px;
  gap: 10px;
  padding: 0 3px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(16, calc(100vw - 70px));
    grid-auto-rows: 50px;
    gap: 2px;

    ${ColorCardStyled} {
      scroll-snap-align: center;
    }

    ${ColorName} {
      display: none;
    }

    ${ColorNameIn} {
      display: block;
    }
  }
`;

export const ScrollLeft = styled.div`
  position: absolute;
  background-color: rgba(0, 0, 0, 0.4);
  left: 0;
  top: calc(50% - 30px);
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  pointer-events: none;

  svg {
    fill: ${({ theme }) => theme.colors.gray1};
    width: 20px;
    transform: rotate(90deg);
  }

  @media (max-width: 480px) {
    width: 15px;

    svg {
      width: 10px;
    }
  }
`;

export const ScrollRight = styled(ScrollLeft)`
  left: unset;
  right: 0;

  svg {
    transform: rotate(-90deg);
  }
`;

export const SectionTitle = styled.h5`
  position: sticky;
  top: 0;
  left: 0;
  margin: 15px 0 5px 0;
`;
