import React from 'react';
import { StyledSpacingPropType, FlexCol } from '@thisisdevelopment/akzonobel-core';
import { InputStyledPropType, LabelStyled, InputStyled, ErrorStyled } from './styled';

type PropTypes = {
  onChange?: (value: string) => void;
  autoFocus?: boolean;
  disabled?: boolean;
  placeholder?: string;
  value?: string;
  label?: string;
  error?: string;
  touched?: boolean;
  type?: 'text' | 'email' | 'password';
  textArea?: boolean;
};

function InputField({
  onChange,
  placeholder,
  value,
  label,
  error,
  touched,
  type = 'text',
  height,
  autoFocus = false,
  disabled = false,
  textArea = false,
  ...rest
}: PropTypes & InputStyledPropType & StyledSpacingPropType) {
  return (
    <FlexCol {...rest}>
      {label && <LabelStyled>{label}</LabelStyled>}
      {textArea ? (
        <InputStyled
          as="textarea"
          value={value}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => onChange?.(e.target.value)}
          placeholder={placeholder}
          height={height}
          autoFocus={autoFocus}
          disabled={disabled}
        />
      ) : (
        <InputStyled
          type={type}
          value={value}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange?.(e.target.value)}
          placeholder={placeholder}
          autoFocus={autoFocus}
          disabled={disabled}
        />
      )}
      {error && touched && <ErrorStyled>{error}</ErrorStyled>}
    </FlexCol>
  );
}

export default InputField;
