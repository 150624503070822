export function getColorNameByBgColorHex(bgColor: string): string {
  const threshold = 130; /* about half of 256. Lower threshold equals more dark text on dark background  */
  const hRed = parseInt(bgColor.substring(0, 2), 16);
  const hGreen = parseInt(bgColor.substring(2, 4), 16);
  const hBlue = parseInt(bgColor.substring(4, 6), 16);
  /*
    https://www.w3.org/TR/AERT/#color-contrast
    Color brightness is determined by the following formula:
    ((Red value X 299) + (Green value X 587) + (Blue value X 114)) / 1000
  */
  const brightness = (hRed * 299 + hGreen * 587 + hBlue * 114) / 1000;

  return brightness > threshold ? 'black' : 'white';
}
