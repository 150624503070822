import React from 'react';
import { Svg, StyledSpacingPropType } from '@thisisdevelopment/akzonobel-core';
import { IconButtonStyled, StyledPropTypes } from './styled';

type PropTypes = {
  onClick: () => void;
  icon: 'Close' | 'Search' | 'Heart' | 'ShoppingBag' | 'Person' | 'Menu';
};

function IconButton({
  onClick,
  icon,
  ...rest
}: PropTypes & StyledPropTypes & StyledSpacingPropType) {
  const Icon = Svg[icon];

  return (
    <IconButtonStyled onClick={() => onClick()} {...rest}>
      <Icon />
    </IconButtonStyled>
  );
}

export default IconButton;
