import axios, { initAxiosInstance } from './axiosConfig';

interface Payload {
  articleNumber: number;
  colorId?: number | null;
  quantity: number;
  referrer: string;
}

interface SkuType {
  sku: {
    article_number: string;
    color_id: string;
  };
}

interface BatchSkuType {
  skus: SkuType[];
}

export default {
  initAxiosInstance,

  addToCart(
    articleNumber: number,
    cccid: number | null,
    quantity: number,
    referrer = ''
  ) {
    const payload: Payload = { articleNumber, quantity, referrer };
    if (cccid) {
      payload.colorId = cccid;
    }
    return axios.post(`/v2/cart`, payload);
  },

  addBatchToCart(skus: BatchSkuType) {
    return axios.post(`/v2/cart-many`, skus);
  },

  checkStock(articleNumber: number, cccid: number | null) {
    return axios.get(`/stock/sku/${articleNumber}${cccid ? `/${cccid}` : ''}`);
  },

  checkBatchStock(skus: BatchSkuType) {
    return axios.post(`/stock/skus`, skus);
  },

  getCartDetails() {
    return axios.get('/order/cart_details', {
      params: { t: new Date().getTime() },
    });
  },
};
