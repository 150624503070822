import React from 'react';

const ColorCardIcon = ({ ...props }) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
      <path d="M204,36H52A16,16,0,0,0,36,52V204a16,16,0,0,0,16,16H204a16,16,0,0,0,16-16V52A16,16,0,0,0,204,36ZM52,52h76V204H52Z" />
    </svg>
  );
};

export default ColorCardIcon;
