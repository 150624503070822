import styled from 'styled-components';
import { Wrapper } from '@thisisdevelopment/akzonobel-core';

export const CmsVideoStyled = styled(Wrapper)`
  background-color: ${({ theme }) => theme.colors.black};
  width: 100%;
  height: 600px;

  img {
    cursor: pointer;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (max-width: 768px) {
    height: 300px;
  }
`;

export const VideoEmbed = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
`;
