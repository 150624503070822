import theme from 'theme';
import styled from 'styled-components';

export const CmsCallToActionStyled = styled.div`
  position: relative;

  h3 {
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.white};
    font-size: 1.4rem;
    font-weight: 600;
    position: absolute;
    top: 25px;
    left: 25px;
    right: 25px;
    z-index: 2;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
  }

  a {
    position: absolute;
    bottom: 25px;
    left: 25px;
    z-index: 2;
    background-color: ${({ theme }) => theme.colors.brandBlue};
    color: ${({ theme }) => theme.colors.white};
    text-decoration: none;
    padding: 10px 20px;
    font-size: 1.2rem;
    font-weight: 600;
    border-radius: 8px;
    font-family: 'Source Sans Pro', sans-serif;
    transition: all ease 0.4s;

    &:hover {
      background-color: ${({ theme }) => theme.colors.white};
      color: ${({ theme }) => theme.colors.brandBlue};
    }
  }

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
  }

  @media ${theme.mediaQuery.mobile} {
    h3 {
      font-size: 1.2rem;
    }

    a {
      font-size: 1rem;
    }
  }
`;
