import { css } from 'styled-components';

export type StyledSpacingPropType = {
  margin?: string;
  mt?: string;
  mr?: string;
  mb?: string;
  ml?: string;
  my?: string;
  mx?: string;
  padding?: string;
  pt?: string;
  pr?: string;
  pb?: string;
  pl?: string;
  py?: string;
  px?: string;
};

export const handleSpacing = (props: StyledSpacingPropType) => css`
  margin: ${props.my || props.mx ? `${props.my || 0} ${props.mx || 0}` : props.margin};
  margin-top: ${props.mt};
  margin-right: ${props.mr};
  margin-bottom: ${props.mb};
  margin-left: ${props.ml};
  padding: ${props.py || props.px ? `${props.py || 0} ${props.px || 0}` : props.padding};
  padding-top: ${props.pt};
  padding-right: ${props.pr};
  padding-bottom: ${props.pb};
  padding-left: ${props.pl};
`;
