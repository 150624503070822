import styled from 'styled-components';
import { Wrapper } from '@thisisdevelopment/akzonobel-core';
import theme from 'theme';

type WysiwygPropType = {
  placement: 'top' | 'bottom';
};

export const WysiwygStyled = styled(Wrapper)<WysiwygPropType>`
  display: flex;
  flex-direction: column;

  div {
    display: flex;
    flex-direction: column;
  }

  img {
    object-fit: contain;
    width: 100%;
    margin: 0.5em 0;
  }

  a {
    color: ${({ theme }) => theme.colors.brandGold};
  }

  p,
  ul,
  ol {
    font-size: 1.3rem;
    text-align: justify;
    width: 80%;
    align-self: center;
    margin: 0.5em 0 0.5em 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    color: ${({ theme }) => theme.colors.brandGold};
    width: 80%;
    align-self: center;
    margin: 1.5em 0 0 0;
  }

  h2 {
    font-size: 1.6rem;
  }

  @media ${theme.mediaQuery.mobile} {
    p {
      font-size: 1.1rem;
      text-align: justify;
    }
  }

  ${({ placement, theme }) =>
    placement === 'top' &&
    `> :first-child {
    font-size: 1.5rem;
  }

  > :first-child::first-letter {
    float: left;
    font-size: 3em;
    line-height: 0.5;
    margin: 0.125em 0.15em 0.125em 0;
    color: ${theme.colors.brandGold};
  }`}
`;
