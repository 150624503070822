import { Cart, BatchStockInfoType } from '../types/ecom';
import useContextReducer, { Action } from '../hooks/useContextReducer';

type State = {
  cart?: Cart;
  batchStockInfo?: BatchStockInfoType[];
};

export enum ActionTypes {
  UpdateCart = 'CART/UPDATE',
  UpdateBatchStockInfo = 'BATCHSTOCKINFO/UPDATE',
}

const initialState: State = {
  cart: undefined,
  batchStockInfo: [],
};

const reducer = (state: State, action: Action<ActionTypes>) => {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.UpdateCart:
      return { ...state, cart: payload.cart };
    case ActionTypes.UpdateBatchStockInfo:
      return { ...state, batchStockInfo: [...(state.batchStockInfo ?? []), payload] };
    default:
      return state;
  }
};

const { Provider, Context } = useContextReducer<State, Action<ActionTypes>>(initialState, reducer);

export { Provider as EcomContextProvider, Context as EcomContext };
