import {
  FlexRow,
  Image,
  Text,
  Wrapper,
  useDebounce,
  useOnKeyDown,
  useSearch,
} from '@thisisdevelopment/akzonobel-core';
import IconButton from 'components/IconButton';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import {
  SearchBarStyled,
  SearchField,
  SearchResultList,
  SearchResultListItem,
  SearchResultTitle,
  SearchResults,
  // ShowAllResults,
  Searching,
} from './styled';

type PropType = {
  onClose: () => void;
};

function SearchBar({ onClose }: PropType) {
  const theme = useTheme();
  const [searchText, setSearchText] = useState<string>('');
  const query = useDebounce(searchText, 400);
  const {
    results: colorResults,
    hasResults: hasColorResults,
    isLoading: isLoadingColor,
    fetchResults: fetchColorResults,
  } = useSearch();
  const {
    results: productResults,
    hasResults: hasProductResults,
    isLoading: isLoadingProduct,
    fetchResults: fetchProductResults,
  } = useSearch();

  useEffect(() => {
    fetchColorResults({ type: 'colors', query });
    fetchProductResults({ type: 'products', query });
  }, [query, fetchColorResults, fetchProductResults]);

  useOnKeyDown(['Escape'], () => {
    onClose();
  });

  return (
    <SearchBarStyled>
      <FlexRow
        position="relative"
        alignItems="center"
        margin="20px 20px 0 20px"
      >
        <IconButton
          icon="Search"
          width="24px"
          height="24px"
          fill={theme.colors.brandGold}
          hooverFill={theme.colors.brandGray200}
          onClick={() => null}
          mr="15px"
        />

        <SearchField
          type="text"
          placeholder="Search for"
          autoFocus={true}
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        ></SearchField>

        <IconButton
          icon="Close"
          width="20px"
          height="20px"
          fill={theme.colors.brandGold}
          hooverFill={theme.colors.brandGray200}
          onClick={onClose}
        />

        {query && (
          <SearchResults>
            {!isLoadingColor && hasColorResults && (
              <>
                <SearchResultTitle>Colours</SearchResultTitle>
                <SearchResultList>
                  {colorResults.map(({ id, display, description, snippet }) => (
                    <SearchResultListItem key={`ColorResult_${id}`}>
                      <Link
                        href={`/en/colours/${id}/${snippet}`}
                        onClick={() => onClose()}
                      >
                        <Wrapper
                          bgColor={`#${display}`}
                          width="25px"
                          height="25px"
                          mr="10px"
                        ></Wrapper>
                        <Text>{description}</Text>
                      </Link>
                    </SearchResultListItem>
                  ))}
                </SearchResultList>
              </>
            )}
            {!isLoadingProduct && hasProductResults && (
              <>
                <SearchResultTitle>Products</SearchResultTitle>
                <SearchResultList>
                  {productResults.map(
                    ({ id, display, description, snippet }) => (
                      <SearchResultListItem key={`ProductResult_${id}`}>
                        <Link
                          href={`/en/products/details/${snippet}`}
                          onClick={() => onClose()}
                        >
                          <Image
                            src={display}
                            alt="Search Result Image"
                            objectFit="contain"
                            width="25"
                            height="25"
                            mr="10px"
                          />
                          <Text>{description}</Text>
                        </Link>
                      </SearchResultListItem>
                    )
                  )}
                </SearchResultList>
              </>
            )}
            {/* {!isLoadingColor && !isLoadingProduct && (hasColorResults || hasProductResults) && (
              <Link href={`/search?query=${query}`}>
                <ShowAllResults onClick={() => onClose()}>
                  Show all results
                </ShowAllResults>
              </Link>
            )} */}
            {(isLoadingColor || isLoadingProduct) && (
              <Searching>Searching...</Searching>
            )}
            {!isLoadingColor &&
              !isLoadingProduct &&
              !hasColorResults &&
              !hasProductResults && <Searching>No results found</Searching>}
          </SearchResults>
        )}
      </FlexRow>
    </SearchBarStyled>
  );
}

export default SearchBar;
