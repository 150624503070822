import React, { useEffect, useState } from 'react';
import Svg from '../Svg';
import useEcom from '../../hooks/useEcom';
import { CartStyled, Badge } from './styled';
import config from '../../config';

type PropTypes = {
  cartUrl?: string;
};

function Cart({ cartUrl = config.cartUrl }: PropTypes) {
  const { cart, updateCart } = useEcom();
  const [changing, setChanging]: [boolean, Function] = useState(true);

  useEffect(() => {
    updateCart();
  }, [updateCart]);

  useEffect(() => {
    if (cart && cart.item_count > 0) {
      setChanging(true);
      setTimeout(() => setChanging(false), 500);
    }
  }, [cart, setChanging]);

  return (
    <CartStyled href={cartUrl}>
      <Svg.Cart />
      {cart && cart.item_count > 0 && <Badge changing={changing}>{cart?.item_count}</Badge>}
    </CartStyled>
  );
}

export default Cart;
