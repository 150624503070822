import styled from 'styled-components';

interface WrapperProps {
  color?: string;
  bgColor?: string;
}

const Wrapper = styled.div<WrapperProps>`
  padding: 10px 0;
  background-color: ${(props) => props.bgColor || 'transparent'};

  &[data-bv-show='rating_summary'] .bv_main_container .bv_numReviews_text,
  &[data-bv-show='rating_summary']
    .bv_main_container
    .bv_button_buttonMinimalist {
    color: ${(props) => props.color || '#fff'}!important;
    font-family: inherit !important;
  }
  &[data-bv-show='rating_summary'] .bv_main_container .bv_numReviews_text:hover,
  &[data-bv-show='rating_summary']
    .bv_main_container
    .bv_button_buttonMinimalist:hover {
    color: ${(props) => props.color || '#fff'}!important;
    font-family: inherit !important;
    opacity: 0.8;
  }

  &[data-bv-show='inline_rating'] .bv_main_container .bv_text {
    color: ${(props) => props.color || '#fff'}!important;
  }
`;

interface ReviewWidgetProps {
  id: string;
  type: 'inline_rating' | 'rating_summary' | 'reviews' | 'questions';
  color?: string;
  bgColor?: string;
}
export const ReviewWidget = ({
  id,
  type,
  color,
  bgColor,
}: ReviewWidgetProps) => {
  return (
    <Wrapper
      data-bv-show={type}
      data-bv-product-id={id}
      color={color}
      bgColor={bgColor}
    />
  );
};
