import { DistanceUnit } from '../types';

export function formatNumber(number: number, locale = 'nl-NL'): string {
  return number.toLocaleString(locale, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
}

export function formatDistance(distanceInMeters: number, distanceUnit: DistanceUnit): string {
  return distanceUnit === DistanceUnit.Metric
    ? `${formatNumber(distanceInMeters / 1000)} km`
    : `${formatNumber(distanceInMeters / 1609, 'en-GB')} miles`;
}
