import { useState, useCallback } from 'react';
import { AxiosError } from 'axios';
import { Color } from '../types';
import api from '../services/bff';

export default function useColor(initialColor?: Color): {
  color?: Color;
  setColor: (color: Color | undefined) => void;
  fetchColor: (cccid: string) => void;
  error: AxiosError | undefined;
  isLoading: boolean;
} {
  const [color, setColor] = useState<Color | undefined>(initialColor);
  const [error, setError] = useState<AxiosError | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchColor = useCallback(async (cccid: string) => {
    const fetch = async (cccid: string) => {
      try {
        setIsLoading(true);
        const resp = await api.getColor(cccid);
        setColor(resp.data);
        setError(undefined);
      } catch (error) {
        setColor(undefined);
        setError(error as AxiosError);
      } finally {
        setIsLoading(false);
      }
    };
    await fetch(cccid);
  }, []);

  return {
    color,
    setColor,
    fetchColor,
    error,
    isLoading,
  };
}
