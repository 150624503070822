import NextImage from 'next/image';
import styled from 'styled-components';
import { StyledBorderPropType, handleBorder } from './border';
import { StyledCommonPropType, handleCommon } from './common';
import { StyledFlexPropType, handleFlex } from './flex';
import { shouldForwardProp } from './helper';
import { StyledSizePropType, handleSize } from './size';
import { StyledSpacingPropType, handleSpacing } from './spacing';
import { StyledTextPropType, handleText } from './text';
export type { StyledSpacingPropType };
export { shouldForwardProp };

type StyledFlexRowType = StyledCommonPropType &
  StyledSpacingPropType &
  StyledBorderPropType &
  StyledSizePropType &
  StyledFlexPropType;

export const FlexRow = styled.div.withConfig<StyledFlexRowType>({
  shouldForwardProp,
})`
  display: flex;
  flex-direction: row;
  ${(props) => handleFlex(props)}
  ${(props) => handleCommon(props)}
  ${(props) => handleSpacing(props)}
  ${(props) => handleBorder(props)}
  ${(props) => handleSize(props)}
`;

type StyledFlexColType = StyledCommonPropType &
  StyledSpacingPropType &
  StyledBorderPropType &
  StyledSizePropType &
  StyledFlexPropType;

export const FlexCol = styled.div.withConfig<StyledFlexColType>({
  shouldForwardProp,
})`
  display: flex;
  flex-direction: column;
  ${(props) => handleFlex(props)}
  ${(props) => handleCommon(props)}
  ${(props) => handleSpacing(props)}
  ${(props) => handleBorder(props)}
  ${(props) => handleSize(props)}
`;

export type StyledWrapperPropType = StyledCommonPropType &
  StyledSpacingPropType &
  StyledBorderPropType &
  StyledSizePropType &
  StyledFlexPropType;

export const Wrapper = styled.div.withConfig<StyledWrapperPropType>({
  shouldForwardProp,
})`
  ${(props) => handleFlex(props)}
  ${(props) => handleCommon(props)}
  ${(props) => handleSpacing(props)}
  ${(props) => handleBorder(props)}
  ${(props) => handleSize(props)}
`;

type StyledSpacerPropType = StyledSpacingPropType & StyledSizePropType;

export const Spacer = styled.div.withConfig<StyledSpacerPropType>({
  shouldForwardProp,
})`
  ${(props) => handleSpacing(props)}
  ${(props) => handleSize(props)}
`;

type StyledListPropType = StyledSpacingPropType &
  StyledFlexPropType &
  StyledCommonPropType &
  StyledBorderPropType;

export const List = styled.ul.withConfig<
  StyledSpacingPropType & StyledListPropType
>({
  shouldForwardProp,
})`
  list-style: none;
  margin: 0;
  padding: 0;
  ${(props) => handleFlex(props)}
  ${(props) => handleCommon(props)}
  ${(props) => handleSpacing(props)}
  ${(props) => handleBorder(props)}
`;

type StyledListItemType = StyledCommonPropType &
  StyledSpacingPropType &
  StyledBorderPropType &
  StyledSizePropType &
  StyledFlexPropType;

export const ListItem = styled.li.withConfig<StyledListItemType>({
  shouldForwardProp,
})`
  margin: 0;
  padding: 0;
  ${(props) => handleFlex(props)}
  ${(props) => handleCommon(props)}
  ${(props) => handleSpacing(props)}
  ${(props) => handleBorder(props)}
  ${(props) => handleSize(props)}
`;

export const Container = styled.div<StyledSizePropType>`
  margin-right: auto;
  margin-left: auto;
  max-width: 1280px;
  padding-right: 15px;
  padding-left: 15px;

  ${(props) => handleSize(props)}

  @media (max-width: 1024px) {
    max-width: 100vw;
    margin-right: 0;
    margin-left: 0;
  }
`;

export const Select = styled.select.withConfig<StyledSpacingPropType>({
  shouldForwardProp,
})`
  border: none;
  outline: none;
  ${(props) => handleSpacing(props)};
`;

export type StyledButtonPropType = StyledSpacingPropType & StyledSizePropType;

export const ButtonElement = styled.button.withConfig<StyledButtonPropType>({
  shouldForwardProp,
})`
  outline: none;
  border: none;
  ${(props) => handleSize(props)}
  ${(props) => handleSpacing(props)};
`;

export type StyledImagePropType = StyledSpacingPropType &
  StyledSizePropType &
  StyledBorderPropType;

export const Image = styled(NextImage).withConfig<StyledImagePropType>({})`
  max-width: 100%;
  ${(props) => handleSpacing(props)};
  ${(props) => handleSize(props)}
  ${(props) => handleBorder(props)}
`;

export const Text = styled.span.withConfig<
  StyledTextPropType & StyledSpacingPropType
>({
  shouldForwardProp,
})`
  ${(props) => handleText(props)};
  ${(props) => handleSpacing(props)};
`;

export const Anchor = styled.a.withConfig<
  StyledTextPropType & StyledSpacingPropType
>({
  shouldForwardProp,
})`
  ${(props) => handleText(props)};
  ${(props) => handleSpacing(props)};
`;

export const Label = styled.label.withConfig<
  StyledTextPropType & StyledSpacingPropType
>({
  shouldForwardProp,
})`
  ${(props) => handleText(props)};
  ${(props) => handleSpacing(props)};
`;

export const Paragraph = styled.p.withConfig<
  StyledTextPropType & StyledSpacingPropType
>({
  shouldForwardProp,
})`
  ${(props) => handleText(props)};
  ${(props) => handleSpacing(props)};
`;

export const H1 = styled.h1.withConfig<
  StyledTextPropType & StyledSpacingPropType
>({
  shouldForwardProp,
})`
  font-weight: 400;
  font-size: 2.6rem;
  margin: 0;
  ${(props) => handleText(props)};
  ${(props) => handleSpacing(props)};
`;

export const H2 = styled.h2.withConfig<
  StyledTextPropType & StyledSpacingPropType
>({
  shouldForwardProp,
})`
  font-weight: 400;
  font-size: 2.3rem;
  margin: 0;
  ${(props) => handleText(props)};
  ${(props) => handleSpacing(props)};
`;
export const H3 = styled.h3.withConfig<
  StyledTextPropType & StyledSpacingPropType
>({
  shouldForwardProp,
})`
  font-weight: 400;
  font-size: 2rem;
  margin: 0;
  ${(props) => handleText(props)};
  ${(props) => handleSpacing(props)};
`;

export const H4 = styled.h4.withConfig<
  StyledTextPropType & StyledSpacingPropType
>({
  shouldForwardProp,
})`
  font-weight: 400;
  font-size: 1.7rem;
  margin: 0;
  ${(props) => handleText(props)};
  ${(props) => handleSpacing(props)};
`;

export const H5 = styled.h5.withConfig<
  StyledTextPropType & StyledSpacingPropType
>({
  shouldForwardProp,
})`
  font-weight: 400;
  font-size: 1.4rem;
  margin: 0;
  ${(props) => handleText(props)};
  ${(props) => handleSpacing(props)};
`;

export const H6 = styled.h5.withConfig<
  StyledTextPropType & StyledSpacingPropType
>({
  shouldForwardProp,
})`
  font-weight: 400;
  font-size: 1.1rem;
  margin: 0;
  ${(props) => handleText(props)};
  ${(props) => handleSpacing(props)};
`;
