import { List, ListItem } from '@thisisdevelopment/akzonobel-core';
import Link from 'next/link';
import styled, { css } from 'styled-components';
import theme from 'theme';

type StyledPropType = {
  isOpen: boolean;
};

export const MenuMobileStyled = styled.div<StyledPropType>`
  position: fixed;
  top: 70px;
  left: 100vw;
  right: 0;
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.colors.brandBlue};
  background-color: ${({ theme }) => theme.colors.brandGray100};
  transition: all ease 0.5s;
  opacity: 0;
  z-index: 23;
  padding: 50px 20px;

  @media ${theme.mediaQuery.tablet} {
    ${({ isOpen }) =>
      isOpen &&
      css`
        left: calc(100vw - 400px);
        opacity: 1;
        display: initial;
      `}
  }

  @media ${theme.mediaQuery.mobile} {
    ${({ isOpen }) =>
      isOpen &&
      css`
        left: 0;
        opacity: 1;
        display: initial;
      `}
  }

  display: none;
`;

export const CloseButtonWrapper = styled.div<StyledPropType>`
  position: fixed;
  top: 0;
  right: -70px;
  border-left: 1px solid ${({ theme }) => theme.colors.brandBlue};
  background-color: ${({ theme }) => theme.colors.brandGray100};
  width: 60px;
  height: 71px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all ease 0.5s;
  opacity: 0;

  svg {
    fill: ${({ theme }) => theme.colors.brandGold};
    width: 36px;
    height: 36px;
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      opacity: 1;
      right: 0;
    `}

  @media ${theme.mediaQuery.desktop} {
    display: none;
  }
`;

export const Menu = styled(List)``;

export const MenuItem = styled(ListItem)`
  border-top: 1px solid ${({ theme }) => theme.colors.brandGray200};
  padding: 10px 0;
`;

export const SubMenuLabel = styled(List)`
  font-family: 'Open Sans', sans-serif;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 0.9rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.brandGold};
  border-bottom: 1px dashed ${({ theme }) => theme.colors.brandGray200};
  border-top: 1px solid ${({ theme }) => theme.colors.brandGray200};
  padding: 10px 0;
`;

export const MenuLink = styled(Link)`
  font-family: 'Open Sans', sans-serif;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 0.9rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.brandGray500};
`;

export const SubMenuItem = styled.ul`
  padding: 10px;
`;
