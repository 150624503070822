import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Store } from '../../types';
import { StyledBorderPropType, handleBorder } from '../../elements/border';
import { ButtonElement } from '../../elements';

export const MapWrapper = styled.div<StyledBorderPropType>`
  position: relative;

  ${(props) => handleBorder(props)}
`;

const Map = styled.div<StyledBorderPropType>`
  height: 100%;
  width: 100%;
`;

type PropTypes = {
  center: { lat: number; lng: number };
  zoom: number;
  stores?: Store[];
  store?: Store;
  onClickMarker: (store: Store) => void;
  searchThisAreaMinZoom: number;
  onSearchThisArea: ({
    center,
    zoom,
  }: {
    center: google.maps.LatLng;
    zoom: number;
  }) => void;
};

export const SearchThisAreaButton = styled(ButtonElement)`
  position: absolute;
  top: 10px;
  left: calc(50% - 75px);
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 35px;
  border: 2px solid ${({ theme }) => theme.colors.gray5};
  font-weight: bold;
  width: 150px;
  height: 35px;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

// Reference: https://developers.google.com/maps/documentation/javascript/examples/places-searchbox

function GoogleMap({
  center,
  zoom,
  stores,
  store,
  onClickMarker,
  searchThisAreaMinZoom,
  onSearchThisArea,
  ...rest
}: PropTypes & StyledBorderPropType) {
  const ref = useRef<HTMLDivElement>(null);
  const map = useRef<google.maps.Map>(null);
  const markers = useRef<google.maps.Marker[]>([]);
  const [showSearchThisArea, setShowSearchThisArea] = useState<boolean>(false);

  const handleSearchThisArea = () => {
    if (!map.current) {
      return;
    }

    setShowSearchThisArea(false);
    onSearchThisArea({
      center: map.current.getCenter(),
      zoom: map.current.getZoom(),
    });
  };

  useEffect(() => {
    if (ref.current) {
      if (!map.current) {
        const mapOptions: google.maps.MapOptions = { center, zoom };
        // eslint-disable-next-line
        // @ts-ignore
        map.current = new google.maps.Map(ref.current, mapOptions);
        map.current.addListener('center_changed', () => {
          // @ts-ignore
          setShowSearchThisArea(map.current.getZoom() >= searchThisAreaMinZoom);
        });
      } else {
        // eslint-disable-next-line
        const position = new google.maps.LatLng(center.lat, center.lng);
        map.current.setCenter(position);
        map.current.setZoom(zoom);
      }
    }
  }, [ref, center, zoom, setShowSearchThisArea]);

  useEffect(() => {
    if (stores && stores.length > 0) {
      markers.current.forEach((marker) => marker.setMap(null));
      markers.current.splice(0);

      // eslint-disable-next-line
      const bounds = new google.maps.LatLngBounds();
      stores.forEach((store) => {
        // eslint-disable-next-line
        const position = new google.maps.LatLng(store.location.latitude, store.location.longitude);

        bounds.extend(position);

        // eslint-disable-next-line

        const marker = new google.maps.Marker({
          // @ts-ignore
          map: map.current,
          title: store.globalId,
          position,
          // icon: {

          //   url: Marker,
          //   // eslint-disable-next-line
          //   scaledSize: new google.maps.Size(30, 30),
          // },
          // eslint-disable-next-line
          animation: google.maps.Animation.DROP,
        });
        marker.addListener('click', (e) => {
          onClickMarker(store);
          e.stop();
        });
        markers.current.push(marker);
      });

      map.current?.fitBounds(bounds);
    }
  }, [stores, onClickMarker]);

  useEffect(() => {
    if (store) {
      // eslint-disable-next-line
      const position = new google.maps.LatLng(store.location.latitude, store.location.longitude);
      map.current?.setCenter(position);
      map.current?.setZoom(15);

      const marker = markers.current.find(
        (marker) => marker.getTitle() === store.globalId
      );
      // eslint-disable-next-line
      marker?.setAnimation(google.maps.Animation.BOUNCE);
      setTimeout(() => marker?.setAnimation(null), 500);
    }
  }, [store]);

  return (
    <MapWrapper {...rest}>
      {showSearchThisArea && (
        <SearchThisAreaButton onClick={handleSearchThisArea}>
          Search this area
        </SearchThisAreaButton>
      )}
      <Map ref={ref}></Map>
    </MapWrapper>
  );
}

export default GoogleMap;
