import { useEffect, useRef } from 'react';

export default function useClickOutside<T>(callback: Function) {
  const ref = useRef<T>(null);

  useEffect(() => {
    const listener = (event: MouseEvent) => {
      if (!ref.current || (ref.current as any).contains(event.target)) {
        return;
      }

      callback(event);
    };

    document.addEventListener('mousedown', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
    };
  }, [ref, callback]);

  return { ref };
}
