import { useMemo, useState, useCallback } from 'react';
import { ProductFiltersSelected, ProductFilters } from '../types';
import api from '../services/bff';
import { filtersFromSearchParams, filtersToParamString } from './helpers';

function useFilters() {
  const selectedFiltersFromSearchParams = useMemo(filtersFromSearchParams, []);
  const [selectedFilters, setSelectedFilters]: [ProductFiltersSelected, Function] = useState(
    selectedFiltersFromSearchParams
  );
  const [filters, setFilters]: [ProductFilters[] | undefined, Function] = useState();

  const fetchFilters = useCallback(async (query: string) => {
    const fetch = async () => {
      const { data } = await api.getFilters(query);
      const activeFilters = data.filter(({ active }) => active).sort((a, b) => a.order - b.order);
      setFilters(activeFilters);
    };
    await fetch();
  }, []);

  return {
    filters,
    fetchFilters,
    selectedFilters,
    setSelectedFilters,
    paramStringFromFilters: useMemo(() => filtersToParamString(selectedFilters), [selectedFilters]),
  };
}

export default useFilters;
