import React, { FC, ReactNode } from 'react';
import { useClickOutside, H1, H2 } from '@thisisdevelopment/akzonobel-core';
import { StyledPropType, ModalStyled, Header } from './styled';

type PropType = {
  title?: string;
  subTitle?: string;
  children: ReactNode;
  onClose: () => void;
};

const Modal: FC<PropType & StyledPropType> = ({
  title,
  subTitle,
  children,
  onClose,
  width,
}: PropType & StyledPropType) => {
  const { ref } = useClickOutside<HTMLDivElement>(onClose);

  return (
    <ModalStyled ref={ref} width={width}>
      {(title || subTitle) && (
        <Header>
          {title && <H1>{title}</H1>}
          {subTitle && <H2>{subTitle}</H2>}
        </Header>
      )}
      {children}
    </ModalStyled>
  );
};

export default Modal;
