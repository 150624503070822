import { useState, useCallback } from 'react';
import { Article } from '../types';
import api from '../services/bff';

export default function useArticle(): {
  isLoading: boolean;
  article: Article | undefined;
  setArticle: (article: Article | undefined) => void;
  fetchArticle: (globalId: string, cccid: number | null, packSize: string) => Promise<void>;
} {
  const [article, setArticle] = useState<Article | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchArticle = useCallback(
    async (globalId: string, cccid: number | null, packSize: string) => {
      const fetch = async () => {
        try {
          setIsLoading(true);
          const resp = await api.getArticle(globalId, cccid, packSize);
          setArticle(resp.data);
        } finally {
          setIsLoading(false);
        }
      };
      await fetch();
    },
    []
  );

  return {
    isLoading,
    article,
    setArticle,
    fetchArticle,
  };
}
