import theme from 'theme';
import styled from 'styled-components';

export type StyledPropType = {
  width?: string;
};

export const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 30;
  background: rgba(0, 0, 0, 0.5);
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 25px;
  justify-content: center;
`;

export const ModalStyled = styled.aside<StyledPropType>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 4px;
  filter: drop-shadow(2px 4px 4px rgba(0, 0, 0, 0.2));
  z-index: 30;
  padding: 30px;
  width: ${({ width }) => width};

  @media ${theme.mediaQuery.mobile} {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    padding: 15px;
  }
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.brandGold};
  padding-bottom: 10px;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 15px;

  h1 {
    font-size: 1.3rem;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    white-space: nowrap;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.brandGold};
  }

  h2 {
    margin-left: 5px;
    font-size: 1rem;
    letter-spacing: 0.1em;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.brandBlueDark};
  }

  @media ${theme.mediaQuery.mobile} {
    padding-bottom: 5px;

    h1 {
      font-size: 1rem;
    }

    h2 {
      font-size: 1rem;
    }
  }
`;
