import React, { ChangeEvent } from 'react';
import { Svg } from '@thisisdevelopment/akzonobel-core';
import { InputNumberStyled, Label, InputWrapper, Button, Input } from './styled';

type PropTypes = {
  value: number;
  label: string;
  onChange: Function;
  min?: number;
  max?: number;
};

function InputNumber({ value, label, onChange, min = 1, max = 99 }: PropTypes) {
  const onChangeValue = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(e.target.value, 10);
    if (newValue < min || isNaN(newValue)) {
      onChange(min);
    } else if (newValue > max) {
      onChange(max);
    } else {
      onChange(newValue);
    }
  };

  return (
    <InputNumberStyled>
      <Label>{label}</Label>
      <InputWrapper>
        <Button onClick={() => onChange(value - 1)} disabled={value <= min}>
          <Svg.Minus />
        </Button>
        <Input type="number" min={min} max={max} value={value} onChange={onChangeValue} />
        <Button onClick={() => onChange(value + 1)} disabled={value >= max}>
          <Svg.Plus />
        </Button>
      </InputWrapper>
    </InputNumberStyled>
  );
}

export default InputNumber;
