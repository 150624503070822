import { useState, useCallback } from 'react';
import orderBy from 'lodash/orderBy';
import maxBy from 'lodash/maxBy';
import flow from 'lodash/flow';
import { Color } from '../types';
import api from '../services/bff';

type FetchOptions = {
  flip90AntiClockwise?: boolean;
  extraColors?: Color[];
};

const extractColors = (colors: { color: Color }[]): Color[] =>
  colors.map(({ color }) => color);

const filterOutDefaultCollection = (colors: Color[]): Color[] =>
  colors.filter((color) => color.colorCollection?.colorCollectionId !== 11329); // Digital Default Colors

const flip90AntiClockwise = (colors: Color[]): Color[] => {
  const maxColumn = maxBy(colors, 'colorWall.column')?.colorWall.column ?? 0;
  return colors.map((color) => ({
    ...color,
    colorWall: {
      page: color.colorWall.page,
      column: color.colorWall.row,
      row: maxColumn + 1 - color.colorWall.column,
    },
  }));
};

const addExtraColors =
  (colors: Color[]) =>
  (extraColors: Color[]): Color[] =>
    [...colors, ...extraColors];

const sortColors = (colors: Color[]): Color[] => {
  return orderBy(colors, [
    'colorWall.page',
    'colorWall.row',
    'colorWall.column',
  ]);
};

export async function fetchColors(
  query?: string,
  fetchOptions?: FetchOptions
): Promise<Color[]> {
  const resp = await api.getColors(query);
  return flow([
    extractColors,
    filterOutDefaultCollection,
    fetchOptions?.flip90AntiClockwise
      ? flip90AntiClockwise
      : (colors: Color[]): Color[] => colors,
    fetchOptions?.extraColors
      ? addExtraColors(fetchOptions?.extraColors)
      : (colors: Color[]): Color[] => colors,
    sortColors,
  ])(resp.data.colors);
}

export default function useColors(): {
  colors: Color[];
  fetchColors: (query?: string, fetchOptions?: FetchOptions) => void;
  isLoading: boolean;
  setColors: (colors: Color[]) => void;
} {
  const [colors, setColors] = useState<Color[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const fetch = useCallback(
    async (query?: string, fetchOptions?: FetchOptions) => {
      const fetch = async (query?: string) => {
        try {
          setIsLoading(true);
          setColors(await fetchColors(query, fetchOptions));
        } finally {
          setIsLoading(false);
        }
      };
      await fetch(query);
    },
    []
  );

  return {
    colors,
    fetchColors: fetch,
    setColors,
    isLoading,
  };
}
