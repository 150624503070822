import styled from 'styled-components';
import { ButtonElement } from '@thisisdevelopment/akzonobel-core';

export type StyledPropTypes = {
  width?: string;
  height?: string;
  fill?: string;
  hooverFill?: string;
};

export const IconButtonStyled = styled(ButtonElement)<StyledPropTypes>`
  outline: none;
  border: none;
  background-color: transparent;
  padding: 0;

  svg {
    fill: ${({ fill }) => fill};
    width: ${({ width }) => width};
    height: ${({ height }) => height};
    transition: all ease 0.3s;
  }

  &:hover {
    cursor: pointer;

    svg {
      fill: ${({ hooverFill, fill }) => hooverFill ?? fill};
    }
  }
`;
