import { useContext, useCallback } from 'react';
import {
  ActionTypes,
  Notification,
  NotificationType,
  NotificationsContext,
} from '../contexts/notifications';

export default function useNotifications(): {
  notifications: Notification[];
  show: (type: NotificationType, message: string) => void;
  clear: (timestamp: number) => void;
} {
  const [state, dispatch] = useContext(NotificationsContext);
  const { notifications } = state;

  const clear = useCallback(
    (timestamp: number) => {
      dispatch({ type: ActionTypes.Clear, payload: { timestamp } });
    },
    [dispatch]
  );

  const show = useCallback(
    (type: NotificationType, message: string) => {
      const timestamp = new Date().getTime();
      const notification: Notification = { timestamp, message, type };
      dispatch({ type: ActionTypes.Show, payload: { notification } });
      setTimeout(() => clear(timestamp), 5000);
    },
    [dispatch, clear]
  );

  return {
    notifications,
    show,
    clear,
  };
}
