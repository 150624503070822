import styled from 'styled-components';
import { Spacer } from '@thisisdevelopment/akzonobel-core';
import theme from 'theme';

export const FooterBlocksStyled = styled(Spacer)`
  background-color: ${({ theme }) => theme.colors.white};
`;

export const FooterBlocksContainer = styled.div`
  gap: 30px;

  @media ${theme.mediaQuery.tablet} {
    display: flex;
    flex-direction: column;
  }

  @media ${theme.mediaQuery.desktop} {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;
