import { config, useClickOutside } from '@thisisdevelopment/akzonobel-core';
import IconButton from 'components/IconButton';
import { FC, Fragment } from 'react';
import {
  CloseButtonWrapper,
  Menu,
  MenuItem,
  MenuLink,
  MenuMobileStyled,
  SubMenuItem,
  SubMenuLabel,
} from './styled';

type PropType = {
  isOpen: boolean;
  onClose: () => void;
};

const MenuMobile: FC<PropType> = ({ isOpen, onClose }: PropType) => {
  const { ref } = useClickOutside<HTMLDivElement>(onClose);
  return (
    <MenuMobileStyled isOpen={isOpen} ref={ref}>
      <CloseButtonWrapper isOpen={isOpen}>
        <IconButton icon="Close" onClick={onClose} />
      </CloseButtonWrapper>
      <Menu>
        {config.menuItems &&
          config.menuItems.map((menuItem, menuItemIndex) =>
            menuItem.items ? (
              <Fragment key={`MenuItem_${menuItemIndex}`}>
                <SubMenuLabel>{menuItem.label}</SubMenuLabel>
                {menuItem.items.map((subMenuItem, subMenuItemIndex) => (
                  <SubMenuItem
                    key={`MenuItem_${menuItemIndex}_${subMenuItemIndex}`}
                  >
                    <MenuLink href={subMenuItem.to} onClick={onClose}>
                      {subMenuItem.label}
                    </MenuLink>
                  </SubMenuItem>
                ))}
              </Fragment>
            ) : (
              <MenuItem key={`MenuItem_${menuItemIndex}`}>
                <MenuLink href={menuItem.to} onClick={onClose}>
                  {menuItem.label}
                </MenuLink>
              </MenuItem>
            )
          )}
      </Menu>
    </MenuMobileStyled>
  );
};

export default MenuMobile;
