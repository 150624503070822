import { Loader } from '@googlemaps/js-api-loader';

export default async function loadGoogleApi(apiKey: string) {
  const loader = new Loader({
    apiKey,
    version: 'weekly',
    libraries: ['places'],
  });

  await loader.load();
}
