import { useEffect } from 'react';

export default function useOnKeyDown(
  keys: string[],
  callback: (key?: string) => void,
  preventDefault?: boolean
) {
  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (keys.includes(event.key)) {
        preventDefault && event.preventDefault();
        callback(event.key);
      }
    };

    document.addEventListener('keydown', listener);

    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [callback]);

  return {};
}
