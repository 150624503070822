import { ReviewWidget } from "@/components/BazaarVoice/";
import {
  H4,
  Image,
  LinkButton,
  Paragraph,
  Product,
} from "@thisisdevelopment/akzonobel-core";
import ProductKeySellingPoints from "components/ProductKeySellingPoints";
import { ReactNode } from "react";
import TagManager from "react-gtm-module";
import { getArgsProductClick } from "util/datalayer";
import { hasColors } from "util/product";
import {
  Content,
  ImageWrapper,
  InfoWrapper,
  ProductCardStyled,
  ProductOptions,
} from "./styled";

type PropTypes = {
  product: Product;
  children: ReactNode;
};

const onProductCardClick = (product: Product, withColorPicker: boolean) => {
  TagManager.dataLayer(getArgsProductClick(product, withColorPicker));
};

function ProductCard({ product, children }: PropTypes) {
  return (
    <ProductCardStyled>
      <Content>
        <ImageWrapper>
          <Image
            width="350"
            height="350"
            src={product.packshots?.[0].m}
            alt={product.name}
          />
        </ImageWrapper>
        <InfoWrapper>
          <H4>{product.name}</H4>
          {product && (
            <ReviewWidget id={product.globalId} type="inline_rating" />
          )}
          <Paragraph textAlign="justify" fontSize="xl">
            {product.shortDescription}
          </Paragraph>
          <ProductKeySellingPoints product={product} />
          <ProductOptions>
            <LinkButton
              color="secondary"
              size="small"
              href={`/en/products/details/${product.uriFriendlyName}`}
              onClick={() => onProductCardClick(product, false)}>
              Product Details
            </LinkButton>
            {hasColors(product) && (
              <LinkButton
                color="secondary"
                size="small"
                href={`/en/products/details/${product.uriFriendlyName}?pickAColour=true`}
                onClick={() => onProductCardClick(product, true)}>
                Pick a colour
              </LinkButton>
            )}
          </ProductOptions>
        </InfoWrapper>
      </Content>
      {children}
    </ProductCardStyled>
  );
}

export default ProductCard;
