import { keyframes, css } from 'styled-components';

export type ThemeAnimations = {
  slideUpFadeIn: any;
};

const slideUpFadeInKeyframes = keyframes`
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const slideUpFadeIn = css`
  animation: ${slideUpFadeInKeyframes} 0.8s;
`;

export const animations: ThemeAnimations = {
  slideUpFadeIn,
};
