import styled from 'styled-components';
import { Wrapper } from '@thisisdevelopment/akzonobel-core';

export const ContainerFull = styled(Wrapper)`
  margin-left: auto;
  margin-right: auto;
  max-width: 1600px;

  @media (max-width: 1600px) {
    max-width: 100vw;
  }
`;

export const ContainerInner = styled(Wrapper)`
  margin-left: 160px;
  margin-right: 160px;

  a {
    font-family: 'Source Sans Pro', sans-serif;
    text-underline-position: under;
    font-weight: 600;
  }

  @media (max-width: 1640px) {
    margin-left: 20px;
    margin-right: 20px;
  }
`;
