import { FlexRow, Svg, config } from '@thisisdevelopment/akzonobel-core';
import HeritageLogo from 'assets/images/logo';
import Cart from 'components/Cart';
import IconButton from 'components/IconButton';
import Menu from 'components/Menu';
import MenuMobile from 'components/MenuMobile';
import SearchBar from 'components/SearchBar';
import { ContainerFull, ContainerInner } from 'elements';
import { useRouter } from 'next/router';
import { FC, useCallback, useEffect, useState } from 'react';
import SubBar from './SubBar';
import {
  Icons,
  LogoLink,
  TabletOnlyIconButton,
  TopbarFixed,
  TopbarStyled,
} from './styled';
import Link from 'next/link';

const Topbar: FC = () => {
  const router = useRouter();
  const isPageWithoutHeaderBackground =
    router.asPath === '/en' || router.asPath.includes('/en/features/');
  const shouldHaveBackground = useCallback(
    () => (isPageWithoutHeaderBackground ? window.scrollY >= 150 : true),
    [isPageWithoutHeaderBackground]
  );
  const shouldHaveBoxShadow = useCallback(
    () =>
      isPageWithoutHeaderBackground
        ? window.scrollY >= 150
        : window.scrollY > 0,
    [isPageWithoutHeaderBackground]
  );
  const [hasBackground, setHasBackground] = useState<boolean>(false);
  const [hasBoxShadow, setHasBoxShadow] = useState<boolean>(false);
  const [isSearchOpen, setIsSearchOpen] = useState<boolean>(false);
  const [isMenuMobileOpen, setIsMenuMobileOpen] = useState<boolean>(false);

  const onScroll = useCallback(() => {
    setHasBackground(shouldHaveBackground());
    setHasBoxShadow(shouldHaveBoxShadow());
  }, [
    setHasBackground,
    shouldHaveBackground,
    setHasBoxShadow,
    shouldHaveBoxShadow,
  ]);

  useEffect(() => {
    window.addEventListener('scroll', onScroll, false);
    onScroll();
    return () => window.removeEventListener('scroll', onScroll, false);
  }, [onScroll]);

  return (
    <TopbarFixed hasSubBar={config.hasSubBar}>
      <ContainerFull height="100%">
        <TopbarStyled hasBackground={hasBackground} hasBoxShadow={hasBoxShadow}>
          <SubBar hasBackground={hasBackground} />
          <ContainerInner>
            <FlexRow justifyContent="space-between">
              <LogoLink href={'/en/'} aria-label="Heritage Home">
                <HeritageLogo />
              </LogoLink>
              {isSearchOpen ? (
                <SearchBar onClose={() => setIsSearchOpen(false)} />
              ) : (
                <>
                  <Menu hasBackground={hasBackground} />
                  <MenuMobile
                    isOpen={isMenuMobileOpen}
                    onClose={() => setIsMenuMobileOpen(false)}
                  />
                  <Icons>
                    <IconButton
                      icon="Search"
                      aria-label="Search Button"
                      onClick={() => setIsSearchOpen(true)}
                    />
                    <Link href="/en/newsletter">
                      <Svg.EmailIcon />
                    </Link>
                    {config.ecomEnabled && <Cart />}
                    <TabletOnlyIconButton
                      icon="Menu"
                      aria-label="Mobile Menu Button"
                      onClick={() => setIsMenuMobileOpen(true)}
                    />
                  </Icons>
                </>
              )}
            </FlexRow>
          </ContainerInner>
        </TopbarStyled>
      </ContainerFull>
    </TopbarFixed>
  );
};

export default Topbar;
