import { css } from 'styled-components';

export type StyledCommonPropType = {
  display?: 'block' | 'none' | 'flex' | 'inline-flex' | 'grid';
  position?: 'absolute' | 'fixed' | 'relative' | 'sticky';
  bgColor?: string;
  overflowY?: 'scroll' | 'none' | 'auto';
};

export const handleCommon = (props: StyledCommonPropType) => css`
  display: ${props.display};
  position: ${props.position};
  background-color: ${props.bgColor};
  overflow-y: ${props.overflowY};
`;
