import styled from 'styled-components';

export const LikeButtonStyled = styled.div<{ top: string; right: string }>`
  position: absolute;
  top: ${(props) => props.top};
  right: ${(props) => props.right};

  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
`;
