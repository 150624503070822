export type ProductFiltersSelected = {
  sheen?: string[];
  surfaces?: string[];
  positions?: string[];
  roomTypes?: string[];
  brands?: string[];
};

export type FilterOption = {
  value: string;
  label: string;
  enabled: boolean;
};

export type ProductFilterType = 'sheen' | 'surfaces' | 'positions' | 'roomTypes' | 'brands';

export enum ProductFilterView {
  ColorList = 'color-list',
  ProductList = 'product-list',
}

export type ProductFilters = {
  multiple: boolean;
  label: string;
  type: ProductFilterType;
  order: number;
  values: FilterOption[];
  active: boolean;
};
