import styled from 'styled-components';

export const ProductInfoStyled = styled.div`
  margin: 25px;
  border-top: 1px solid ${({ theme }) => theme.colors.brandGray450};
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 0.9rem;

  h3 {
    font-weight: 600;
    font-size: 0.9rem;
    margin-top: 20px;
    margin-bottom: 5px;
  }
`;
