import React from 'react';
import { WysiwygStyled } from './styled';

type PropTypes = {
  content: string;
  placement?: 'top' | 'bottom';
};

function Wysiwyg({ content, placement }: PropTypes) {
  return (
    <WysiwygStyled dangerouslySetInnerHTML={{ __html: content }} placement={placement ?? 'top'} />
  );
}

export default Wysiwyg;
