export type Publication = {
  startDate?: any;
  endDate?: any;
  mutationDate: Date;
  deleteFlag: boolean;
};

export type Address = {
  address1: string;
  address2: string;
  zip: string;
  city: string;
  region: string;
  state: string;
  country: string;
  countryCode: string;
  email: string;
  website: string;
  fax: string;
  primaryPhone: string;
  secondaryPhone: string;
};

export type Location = {
  longitude: number;
  latitude: number;
};

export type Store = {
  publication: Publication;
  address: Address;
  location: Location;
  distance: string;
  distanceUnit: string;
  distanceValue: number;
  brands: string[];
  companyName: string;
  attributeCodes: string[];
  globalId: string;
};

export enum DistanceUnit {
  Metric = 'm',
  Imperial = 'i',
}
