import config from '../config';
import get from 'lodash/get';

export default function useI18n(): { t: (key: string) => string } {
  const t = (key: string) => {
    const tranlation = get(config.translations, `${config.language}.${key}`);
    return tranlation ?? key;
  };

  return {
    t,
  };
}
