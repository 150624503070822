import {
  Color,
  FlexRow,
  H1,
  H2,
  H3,
  H4,
  Image,
  Paragraph,
  Product,
  Svg,
  getColorNameByBgColorHex,
} from '@thisisdevelopment/akzonobel-core';
import Article from 'components/Article';
import CmsImage from 'components/CmsImage';
import ColorPicker from 'components/ColorPicker';
import OrderFormModal from 'components/OrderFormModal';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTheme } from 'styled-components';
import { hasColors } from 'util/product';
import {
  ArticleContentWrapper,
  ArticleHeader,
  ArticleWrapper,
  Background,
  ChooseButton,
  ColorDescription,
  ColorPickerWrapper,
  ColorsHeader,
  ColorsWrapper,
  Content,
  Header,
  ProductDescription,
} from './styled';

type PropType = {
  mode: 'Product' | 'Color';
  product?: Product;
  color?: Color;
  colorsGrouped: { [key: string]: Color[] };
  colorProducts?: Product[];
  backgroundImage?: string | number;
  openColors?: boolean;
};

const ProductColor: FC<PropType> = ({
  product,
  color,
  colorsGrouped,
  backgroundImage,
  mode,
  colorProducts,
  openColors = false,
}: PropType) => {
  const theme = useTheme();
  const [isArticleOpen, setIsArticleOpen] = useState<boolean>(
    mode === 'Product'
  );
  const [isColorsOpen, setIsColorsOpen] = useState<boolean>(openColors);
  const isTablet = useMediaQuery(theme.mediaQuery.tablet);
  const [orderModalIsOpen, setOrderModalIsOpen] = useState(false);

  const textColor = useMemo(
    () =>
      mode === 'Color' && color
        ? getColorNameByBgColorHex(color.rgb)
        : theme.colors.black,
    [color, mode, theme.colors.black]
  );

  useEffect(() => {
    setIsColorsOpen(openColors);
  }, [openColors]);

  const toggleColors = () => {
    setIsColorsOpen((isOpen) => !isOpen);
    isTablet && window.scroll({ top: 0 });
  };

  return (
    <>
      {orderModalIsOpen && (
        <OrderFormModal
          title={'Order a Colour Card'}
          subTitle={'Fill in your details to order a colour card'}
          successText={'Thank you!'}
          onClose={() => setOrderModalIsOpen(false)}
          width="500px"
        />
      )}
      <Header style={{ color: textColor, flexDirection: 'row-reverse' }}>
        <FlexRow alignItems="center">
          {mode === 'Product' && product && hasColors(product) && (
            <>
              {color ? (
                <H2>Choose another colour</H2>
              ) : (
                <H2>Choose a colour</H2>
              )}
              <ChooseButton onClick={toggleColors}>+</ChooseButton>
            </>
          )}
          {mode === 'Color' && (
            <>
              {color ? (
                <H2>Choose another colour</H2>
              ) : (
                <H2>Choose a colour</H2>
              )}
              <ChooseButton onClick={toggleColors}>+</ChooseButton>
            </>
          )}
        </FlexRow>
        {mode === 'Product' && product && <H1>{product.name}</H1>}
        {mode === 'Color' && color && (
          <H1>
            <span>You selected: </span>
            <strong>{color.defaultColorName}</strong>
          </H1>
        )}
      </Header>

      <Content>
        <Background>
          {/* TODO: fix typing, or better, should be separate components from the start */}
          {mode === 'Product' && color && product && hasColors(product) ? (
            <Image
              src={`${backgroundImage}`}
              alt="Background Image"
              width={'1920'}
              height={'680'}
            />
          ) : (
            backgroundImage && (
              <CmsImage id={+backgroundImage} alt={'Background Image'} />
            )
          )}
          {mode === 'Color' && color && color.inspirationalImages?.[4].url && (
            <Image
              src={color.inspirationalImages?.[4].url}
              alt="Inspirational Colour Image"
              width={'1920'}
              height={'680'}
            />
          )}
        </Background>
        {mode === 'Product' && product && (
          <ProductDescription>
            {product?.shortDescription && <H2>Product description</H2>}
            <Paragraph>{product?.shortDescription}</Paragraph>
          </ProductDescription>
        )}
        {mode === 'Color' && color && (
          <ColorDescription textColor={textColor}>
            <Paragraph>{color.description}</Paragraph>
          </ColorDescription>
        )}
        <ArticleWrapper collapsed={!isArticleOpen}>
          <ArticleHeader onClick={() => setIsArticleOpen((isOpen) => !isOpen)}>
            {product && hasColors(product) ? (
              <H2>Ready to buy this colour?</H2>
            ) : (
              <H2>Ready to buy this product?</H2>
            )}
            {isArticleOpen ? <Svg.ExpandMore /> : <Svg.ExpandLess />}
          </ArticleHeader>
          <ArticleContentWrapper>
            <Article
              product={product}
              color={color}
              colorProducts={colorProducts}
              mode={mode}
              setOrderModalIsOpen={setOrderModalIsOpen}
              onChangeColor={toggleColors}
            />
          </ArticleContentWrapper>
        </ArticleWrapper>

        {(mode === 'Color' ||
          (mode === 'Product' && product && hasColors(product))) && (
          <ColorsWrapper collapsed={!isColorsOpen}>
            <ColorsHeader onClick={toggleColors}>
              {isColorsOpen ? (
                <>
                  <H4>+</H4>
                  <H2>Close</H2>
                </>
              ) : (
                <>
                  <H3>+</H3>
                  <H2>Colours</H2>{' '}
                </>
              )}
            </ColorsHeader>
            <ColorPickerWrapper>
              <ColorPicker
                collapsed={!isColorsOpen}
                mode={mode}
                colorsGrouped={colorsGrouped}
              />
            </ColorPickerWrapper>
          </ColorsWrapper>
        )}
      </Content>
    </>
  );
};

export default ProductColor;
