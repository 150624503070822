import { css } from 'styled-components';

export type StyledBorderPropType = {
  border?: string;
  borderColor?: string;
  borderRadius?: string;
  rounded?: boolean;
};

export const handleBorder = (props: StyledBorderPropType) => css`
  border-width: ${props.border};
  border-color: ${props.borderColor};
  border-style: ${props.border && 'solid'};
  border-radius: ${props.rounded ? '100vh' : props.borderRadius};
`;
