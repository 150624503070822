import styled, { css, keyframes } from 'styled-components';
import { List, ListItem } from '@thisisdevelopment/akzonobel-core';

export const CartStyled = styled.div`
  position: relative;
`;

const changingAnimation = keyframes`
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
`;

type BadgePropTypes = {
  changing: boolean;
};

export const Badge = styled.span<BadgePropTypes>`
  display: grid;
  place-items: center;
  position: absolute;
  top: -5px;
  right: -5px;
  width: 18px;
  height: 18px;
  border-radius: 18px;
  background-color: ${({ theme }) => theme.colors.brandBlue};
  color: ${({ theme }) => theme.colors.white};
  font-size: 0.7rem;
  font-weight: 600;
  font-family: 'Source Sans Pro', sans-serif;
  padding-left: 2px;
  pointer-events: none;

  ${({ changing }) =>
    changing &&
    css`
      animation: ${changingAnimation} 0.5s;
    `};
`;

export const EmptyCart = styled.div`
  display: grid;
  place-items: center;
  height: 100px;
  font-family: 'Source Sans Pro', sans-serif;
  color: ${({ theme }) => theme.colors.brandGray400};
  border-bottom: 1px solid ${({ theme }) => theme.colors.brandGold};
  font-size: 0.9rem;
`;

export const Items = styled(List)`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 400px;
  gap: 12px;
  padding: 20px 0;
`;

export const Item = styled(ListItem)`
  display: flex;
  gap: 8px;

  p {
    margin: 0;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 0.8rem;
    color: ${({ theme }) => theme.colors.brandGray400};
  }
`;

export const Footer = styled.footer`
  border-top: 1px solid ${({ theme }) => theme.colors.brandGold};
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
`;
