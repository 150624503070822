import theme from 'theme';
import styled from 'styled-components';

export type StyledPropType = {
  width?: string;
};

export const ModalStyled = styled.aside<StyledPropType>`
  position: absolute;
  top: 60px;
  right: -40px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
  filter: drop-shadow(2px 4px 4px rgba(0, 0, 0, 0.2));
  z-index: 30;
  padding: 30px;
  width: ${({ width }) => width};

  &:before {
    position: absolute;
    top: -50px;
    right: 30px;
    content: '';
    border: 25px solid transparent;
    border-bottom-color: ${({ theme }) => theme.colors.white};
  }

  @media ${theme.mediaQuery.mobile} {
    position: fixed;
    top: 80px;
    right: 10px;
    left: 10px;
    width: unset;
    padding: 15px;

    &:before {
      right: 50px;
    }
  }
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.brandGold};
  padding-bottom: 10px;

  h1 {
    font-size: 1.3rem;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    white-space: nowrap;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.brandGold};
  }

  h2 {
    margin-left: 5px;
    font-size: 1.3rem;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    white-space: nowrap;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.brandBlueDark};
  }

  @media ${theme.mediaQuery.mobile} {
    padding-bottom: 5px;

    h1 {
      font-size: 1rem;
    }

    h2 {
      font-size: 1rem;
    }
  }
`;
