const form = {
  formName: 'contactus-form',
  formVersion: 1,
  siteCode: 'eieher',
  language: 'en',
  formData: [
    {
      id: 'name',
      desc: 'Name',
      type: 'text',
      values: [],
    },
    {
      id: 'email',
      desc: 'Email',
      type: 'text',
      values: [],
    },
    {
      id: 'address',
      desc: 'Address',
      type: 'text',
      values: [],
    },
    {
      id: 'eircode',
      desc: 'Eircode',
      type: 'text',
      values: [],
    },
    {
      id: 'town',
      desc: 'Town/City',
      type: 'text',
      values: [],
    },
    {
      id: 'telephone',
      desc: 'Phone number',
      type: 'text',
      values: [],
    },
  ],
};

export default form;
