import styled from 'styled-components';

export const InputNumberStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
`;

export const Label = styled.label`
  font-size: 0.7rem;
  font-family: 'Source Sans Pro', sans-serif;
  color: ${({ theme }) => theme.colors.brandGray450};
`;

export const InputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.brandGray400};
  padding: 5px 10px;
`;

export const Input = styled.input`
  width: 80px;
  border: none;
  outline: none;
  text-align: center;
  font-size: 1rem;
  font-weight: 600;
  padding: 0;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const Button = styled.button`
  border: none;
  outline: none;
  background-color: transparent;
  padding: 0;

  svg {
    fill: ${({ theme }) => theme.colors.brandGold};
    color: ${({ theme }) => theme.colors.brandGold};
    width: 14px;
  }

  &:hover:not(:disabled) {
    cursor: pointer;

    svg {
      opacity: 0.8;
    }
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;
