import React from 'react';
import { Product, List, ListItem, Text } from '@thisisdevelopment/akzonobel-core';

type PropTypes = {
  product: Product;
};

function ProductKeySellingPoints({ product }: PropTypes) {
  return (
    <List>
      {product.keySellingPoints.map((keyPoint) => (
        <ListItem key={keyPoint.orderNum} mb="5px">
          <Text ml="10px" fontSize="xl">
            * {keyPoint.description}
          </Text>
        </ListItem>
      ))}
    </List>
  );
}

export default ProductKeySellingPoints;
