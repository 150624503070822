import { useState, useCallback, useEffect } from 'react';
import useCmsFile from './useCmsFile';

export default function useCmsImage(): {
  image: HTMLImageElement | undefined;
  file: any;
  fetchImage: (id: number) => void;
  isLoading: boolean;
} {
  const { file, fetchFile } = useCmsFile();
  const [image, setImage] = useState<HTMLImageElement | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchImage = useCallback(
    (id: number) => {
      fetchFile(id);
      setIsLoading(true);
    },
    [fetchFile]
  );

  // useEffect(() => {
  //   if (file) {
  //     const img = new Image();
  //     img.onload = () => {
  //       setIsLoading(false);
  //       setImage(img);
  //     };
  //     img.src = file.data.full_url;
  //     img.onerror = () => {
  //       setIsLoading(false);
  //     };
  //   }
  // }, [file, setImage]);

  return { image, file, fetchImage, isLoading };
}
