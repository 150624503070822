import React, { FC } from 'react';
import { validation, useForm, Button } from '@thisisdevelopment/akzonobel-core';
import InputField from 'components/InputField';
import { TextBlock, Title } from './styled';

type PropType = {
  email: string;
  onSuccess: () => void;
};

const SignInSecondStep: FC<PropType> = ({ onSuccess, email }: PropType) => {
  const initialValues = {
    email,
    password: '',
  };

  const formValidation = {
    password: [validation.required],
  };

  const { bindInput, handleSubmit } = useForm(initialValues, formValidation);

  const onContinue = () =>
    handleSubmit(() => {
      onSuccess();
    });

  return (
    <>
      <Title>Welcome Back!</Title>
      <TextBlock>Now fill in your password</TextBlock>
      <InputField
        placeholder="EMAIL ADDRESS"
        mb="20px"
        type="email"
        disabled
        {...bindInput('email')}
      />
      <InputField
        placeholder="PASSWORD"
        mb="20px"
        type="password"
        autoFocus
        {...bindInput('password')}
      />
      <Button
        ml="auto"
        color="secondary"
        size="medium"
        onClick={onContinue}
        width="150px"
      >
        CONTINUE
      </Button>
    </>
  );
};

export default SignInSecondStep;
