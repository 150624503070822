import React, { useRef, useState, useEffect, useMemo } from 'react';
import { InspirationalImage } from '../../types';
import Svg from '../Svg';
import { ColorPhotosStyled, ScrollLeft, Photos, ScrollRight, Bullets, Bullet } from './styled';
import { Wrapper } from '../../elements';

type PropTypes = {
  inspirationalImages: InspirationalImage[];
};

type Photo = {
  index: number;
  image: HTMLImageElement;
};

function ColorPhotos({ inspirationalImages }: PropTypes) {
  const carrousel = useRef<HTMLDivElement>(null);
  const [photos, setPhotos]: [Photo[], Function] = useState([]);
  const [pages, setPages]: [number, Function] = useState(0);
  const [page, setPage]: [number, Function] = useState(0);

  useEffect(() => {
    const preloadImages = inspirationalImages.map(
      (inspirationalImage, index) =>
        new Promise((resolve, reject) => {
          const image = new Image();
          image.onload = () => resolve({ index, image });
          image.onerror = reject;
          image.src = inspirationalImage.url;
        })
    );

    Promise.all(preloadImages).then((photos) => setPhotos(photos));
  }, [inspirationalImages, setPhotos]);

  useEffect(() => {
    if (photos.length > 0) {
      const determineNumberOfPages = (carrouselProps: HTMLDivElement, numberOfPhotos: number) => {
        const { offsetWidth, scrollWidth } = carrouselProps;
        if (offsetWidth > 0 && scrollWidth > 0 && scrollWidth / offsetWidth < numberOfPhotos) {
          return Math.ceil(scrollWidth / offsetWidth);
        } else {
          return numberOfPhotos;
        }
      };

      const calcPageSize = () => {
        if (carrousel.current) {
          setPages(determineNumberOfPages(carrousel.current, photos.length));
          setPage(1);
          carrousel.current.scrollTo({ left: 0 });
        }
      };

      calcPageSize();

      window.addEventListener('resize', calcPageSize, false);
      return () => window.removeEventListener('resize', calcPageSize, false);
    }
  }, [photos]);

  const bullets = useMemo(() => {
    return pages
      ? Array(pages)
          .fill(null)
          .map((item, index) => index + 1)
      : [];
  }, [pages]);

  const scrollPhotos = (number: number) => {
    if (carrousel.current) {
      const { scrollLeft, offsetWidth } = carrousel.current;
      carrousel.current.scrollTo({
        left: scrollLeft + offsetWidth * number,
        behavior: 'smooth',
      });
    }
  };

  const scrollLeft = () => {
    setPage((page: number) => page - 1);
    scrollPhotos(-1);
  };

  const scrollRight = () => {
    setPage((page: number) => page + 1);
    scrollPhotos(1);
  };

  const goToPage = (newPage: number) => {
    scrollPhotos(newPage - page);
    setPage(newPage);
  };

  return (
    <ColorPhotosStyled>
      <Wrapper position="relative">
        <ScrollLeft onClick={scrollLeft} disabled={page === 1}>
          <Svg.Carret />
        </ScrollLeft>
        <Photos ref={carrousel} numberOfPhotos={photos.length}>
          {photos.map((photo) => (
            <img key={`Photo_${photo.index}`} src={photo.image.src} alt="Inspiration" />
          ))}
        </Photos>
        <ScrollRight onClick={scrollRight} disabled={page === pages}>
          <Svg.Carret />
        </ScrollRight>
      </Wrapper>
      <Bullets>
        {bullets.map((bullet) => (
          <Bullet
            key={`Bullet_${bullet}`}
            selected={bullet === page}
            onClick={() => goToPage(bullet)}
          />
        ))}
      </Bullets>
    </ColorPhotosStyled>
  );
}

export default ColorPhotos;
