import React, { FC, useState } from 'react';
import Modal from 'components/Modal';
import SignInFirstStep from './SignInFirstStep';
import SignInSecondStep from './SignInSecondStep';

type PropType = {
  onClose: () => void;
};

type SignInSteps =
  | 'SignInFirstStep'
  | 'SignInSecondStep'
  | 'RegisterFirstStep'
  | 'RegisterSecondStep'
  | 'LoginSuccess';

const SignInModal: FC<PropType> = ({ onClose }: PropType) => {
  const [step, setStep] = useState<SignInSteps>('SignInFirstStep');
  const [email, setEmail] = useState<string>('');

  return (
    <Modal title="Sign In" onClose={onClose} width="350px">
      {step === 'SignInFirstStep' && (
        <SignInFirstStep
          onSuccess={(values) => {
            setEmail(values.email);
            setStep('SignInSecondStep');
          }}
        />
      )}
      {step === 'SignInSecondStep' && (
        <SignInSecondStep
          email={email}
          onSuccess={() => {
            setStep('LoginSuccess');
          }}
        />
      )}
    </Modal>
  );
};

export default SignInModal;
