/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { config, Color, Wrapper, H4 } from '@thisisdevelopment/akzonobel-core';
import { useTheme } from 'styled-components';
import { ContainerFull } from 'elements';

type PropType = {
  color?: Color;
};

function Sprinklr({ color }: PropType) {
  const theme = useTheme();
  const tick = useRef<any>();
  const [hasPosts, setHasPosts] = useState<boolean>(true);

  const id = `${config.sprinklrClientId}-${config.sprinklrEmbedId}`;
  const getEmbed = () => (window as any).SprinklrEmbeds && (window as any).SprinklrEmbeds[id];

  useEffect(() => {
    const script = document.createElement('script');
    script.id = `sprinklr-embed-loader-${id}`;
    script.type = 'text/javascript';
    script.src = `https://gallery.sprinklr.com/clients/${config.sprinklrClientId}/embeds/${config.sprinklrEmbedId}/script.js`;
    document.body.appendChild(script);

    return () => {
      const embed = getEmbed();
      if (embed) {
        embed.destroy();
      }
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    clearInterval(tick.current);
    tick.current = setInterval(() => {
      const embed = getEmbed();
      if (embed?.posts?.posts) {
        setHasPosts(embed.posts.posts.length > 0);
      }
    }, 1000);

    return () => {
      clearInterval(tick.current);
    };
  }, [setHasPosts, tick]);

  useEffect(() => {
    const embed = getEmbed();
    if (embed) {
      embed.setCustomFieldId(color ? color.defaultColorName : undefined);
      embed.destroy();
      embed.load();
    }
  }, [color]);

  return (
    <ContainerFull>
      <Wrapper
        padding={hasPosts ? '30px' : '0'}
        borderRadius="3px"
        bgColor={theme.colors.white}
        style={{ visibility: hasPosts ? 'visible' : 'hidden' }}
      >
        {hasPosts && (
          <H4 textAlign="center" color={theme.colors.brandGold} mb="20px" fontWeight="extrabold">
            SHARE YOUR INSPIRATION
          </H4>
        )}
        <div
          id={`sprinklr-embed-control-${config.sprinklrClientId}-${config.sprinklrEmbedId}`}
          className="swe_embed_controls"
        ></div>

        <div
          id={`sprinklr-embed-${config.sprinklrClientId}-${config.sprinklrEmbedId}`}
          className="swe_embed"
          data-filter-product=""
          data-filter-custom-field={color ? color.defaultColorName : undefined}
        ></div>
      </Wrapper>
    </ContainerFull>
  );
}

export default Sprinklr;
