import React, { createContext, Dispatch, useReducer, Reducer } from 'react';

export type Action<T> = {
  type: T;
  payload?: any;
};

export type ContextProvider = ({ children }: { children: any }) => JSX.Element;

export default function useContextReducer<S, A>(
  initialState: S,
  reducer: Reducer<S, A>
): { Context: React.Context<[S, Dispatch<A>]>; Provider: ContextProvider } {
  const Context = createContext({} as [S, Dispatch<A>]);
  const Provider = ({ children }: any) => {
    return (
      <Context.Provider value={useReducer(reducer, initialState)}>{children}</Context.Provider>
    );
  };

  return {
    Context,
    Provider,
  };
}
