import styled from 'styled-components';
import theme from 'theme';
import { config } from '@thisisdevelopment/akzonobel-core';
import NextImage from 'next/image';
export const PageStyled = styled.main<{ bgColor: string }>`
  height: 100%;
  background-color: ${({ bgColor }) => bgColor};
`;

type HeaderPropType = {
  $color: string;
  $prefixColor?: string;
  $hasImage?: boolean;
};

const paddingTop = 150;

export const Header = styled.header<HeaderPropType>`
  padding-top: ${({ $hasImage }) =>
    `${
      config.hasSubBar
        ? $hasImage
          ? 40
          : paddingTop + config.subBarHeight
        : $hasImage
        ? 40
        : paddingTop
    }px`};

  h1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
    font-size: 3rem;
    letter-spacing: 0.4rem;
    text-transform: uppercase;
    font-weight: 600;
    color: ${({ $color }) => $color};
    text-align: center;

    &::after {
      content: '';
      width: 100px;
      height: 1px;
      background-color: ${({ $color }) => $color};
      margin-top: 20px;
    }
  }

  h2 {
    margin-bottom: 20px;
    font-size: 1.6rem;
    letter-spacing: 0.4rem;
    text-transform: uppercase;
    font-weight: 600;
    color: ${({ $prefixColor }) => $prefixColor};
    text-align: center;
  }

  p {
    text-align: center;
    font-size: 1.2rem;
    margin-bottom: 30px;
  }

  @media ${theme.mediaQuery.tablet} {
    padding-top: 40px;

    h1 {
      font-size: 1.4rem;
    }

    h2 {
      font-size: 1.2rem;
    }
  }

  @media ${theme.mediaQuery.mobile} {
    h1 {
      font-size: 1.2rem;
      letter-spacing: 0.2rem;
    }

    h2 {
      font-size: 1rem;
      letter-spacing: 0.2rem;
    }

    p {
      font-size: 1rem;
    }
  }
`;

export const Spacer = styled.div`
  @media ${theme.mediaQuery.desktop} {
    height: 154px;
  }

  @media ${theme.mediaQuery.tablet} {
    height: 0px;
  }

  @media ${theme.mediaQuery.mobile} {
    height: 0px;
  }
`;

export const Image = styled(NextImage)`
  width: 100%;
  height: auto;
`;
