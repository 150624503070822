import styled from 'styled-components';
import { ButtonElement, shouldForwardProp } from '@thisisdevelopment/akzonobel-core';
import { mediaQuery } from 'theme';

type StockIndicatorPropType = {
  indicator: string;
};

export const ProductPhoto = styled.div.withConfig<{ bgColor: string }>({ shouldForwardProp })`
  background-color: ${({ theme }) => theme.colors.brandGold};
  text-align: center;
  padding: 10px 30px;
  margin-bottom: 25px;

  img {
    height: 150px;
    width: 100%;
    object-fit: contain;
    background-color: ${({ bgColor }) => bgColor};
    border: 1px solid ${({ theme }) => theme.colors.brandGray200};
  }

  @media ${mediaQuery.mobile} {
    background-color: ${({ theme }) => theme.colors.white};
  }
`;

export const ArticleForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 0 25px;
`;

export const ColorTile = styled.div`
  margin: 25px;
  position: relative;
  height: 100px;

  h3 {
    position: relative;
    top: 10px;
    left: 10px;
    font-size: 0.8rem;
  }

  button {
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-size: 0.8rem;
    border: none;
    background-color: transparent;
    text-decoration: underline;
    color: inherit;
    cursor: pointer;
  }
`;

export const ChooseColorButton = styled(ButtonElement)`
  padding: 8px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 3px;
  border: 1px solid ${({ theme }) => theme.colors.brandGold};
  color: ${({ theme }) => theme.colors.brandGold};
  font-size: 1rem;
  text-align: left;
  cursor: pointer;
`;

export const PriceAndQuantity = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;

  h4 {
    padding: 8px 0;
    color: ${({ theme }) => theme.colors.brandGold};
    font-size: 1.2rem;
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;

  a,
  button {
    flex: 1;
  }
`;

export const StockIndicator = styled.div<StockIndicatorPropType>`
  display: flex;
  flex-direction: row;
  margin-right: auto;
  padding: 4px 0;
  font-size: 1rem;
  align-items: center;
  font-weight: 500;

  > :first-child {
    height: 15px;
    width: 15px;
    background-color: ${({ indicator }) => {
      switch (indicator) {
        case 'no':
          return 'rgb(167, 9, 39)';
        case 'low':
          return 'rgb(247, 218, 153)';
        case 'normal':
          return 'rgb(148, 205, 74)';
        default:
          return 'white';
      }
    }};
    border-radius: 50%;
    margin-right: 8px;
    margin-left: 5px;
  }
`;
