import React, { FC } from 'react';
import { useTheme } from 'styled-components';
import { FlexRow } from '@thisisdevelopment/akzonobel-core';
import Spinner from 'components/Spinner';

const Loading: FC = () => {
  const theme = useTheme();

  return (
    <FlexRow
      justifyContent="center"
      alignItems="center"
      height="100%"
      bgColor={theme.colors.white}
    >
      <Spinner />
    </FlexRow>
  );
};

export default Loading;
