import React from 'react';
import styled from 'styled-components';
import { Select, StyledSpacingPropType, FlexCol, Label } from '../../elements';

export type SelectOption = {
  value: string;
  label: string;
};

type PropTypes = {
  options: SelectOption[];
  onChange?: (value: string) => void;
  placeholder?: string;
  value?: string;
  label?: string;
  error?: string;
  touched?: boolean;
};

const SelectStyled = styled(Select)`
  ${(props) => props.theme.inputSelect?.select};
`;

const LabelStyled = styled(Label)`
  margin-bottom: 5px;
  ${(props) => props.theme.inputSelect?.label};
`;

const ErrorStyled = styled.span`
  ${(props) => props.theme.inputSelect?.error};
`;

function InputSelect({
  options,
  onChange,
  placeholder,
  value,
  label,
  error,
  touched,
  ...rest
}: PropTypes & StyledSpacingPropType) {
  return (
    <FlexCol {...rest}>
      {label && <LabelStyled>{label}</LabelStyled>}
      <SelectStyled onChange={(e) => onChange?.(e.target.value)} value={value ?? ''}>
        <option value="" disabled hidden>
          {placeholder}
        </option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </SelectStyled>
      {error && touched && <ErrorStyled>{error}</ErrorStyled>}
    </FlexCol>
  );
}

export default InputSelect;
