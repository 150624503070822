import React, { FC } from 'react';
import { Product, H3, List, ListItem } from '@thisisdevelopment/akzonobel-core';
import { ProductInfoStyled } from './styled';
type PropType = {
  product: Product;
};

const ProductInfo: FC<PropType> = ({ product }: PropType) => {
  return (
    <ProductInfoStyled>
      <H3>Key Information</H3>
      <List>
        <ListItem>
          <strong>Finish:</strong> {product.sheen}
        </ListItem>
        <ListItem>
          <strong>Coverage:</strong> {product.coverage}
        </ListItem>
        <ListItem>
          <strong>Drying time:</strong> {product.dryingTimeDescription}
        </ListItem>
        <ListItem>
          <strong>Coats:</strong> {product.numberOfCoats}
        </ListItem>
      </List>
      <H3>Product Features</H3>
      <List>
        {product.keySellingPoints.map((keyPoint) => (
          <ListItem key={`KeyPoint_${keyPoint.orderNum}`}>{keyPoint.description}</ListItem>
        ))}
      </List>
    </ProductInfoStyled>
  );
};

export default ProductInfo;
