import React, { FC } from 'react';
import { H2, StyledSpacingPropType } from '@thisisdevelopment/akzonobel-core';
import RelatedArticle, { CmsRelatedArticleType } from 'components/CmsRelatedArticle';
import { RelatedArticlesStyled, RelatedArticlesWrapper } from './styled';

type PropType = {
  title: string;
  items: CmsRelatedArticleType[];
};

const CmsRelatedArticles: FC<PropType & StyledSpacingPropType> = ({
  items,
  title,
  ...rest
}: PropType & StyledSpacingPropType) => {
  return (
    <RelatedArticlesStyled {...rest}>
      <H2>{title}</H2>
      <RelatedArticlesWrapper>
        {items.map((item, index) => (
          <RelatedArticle key={`RelatedArticles_${index}`} relatedArticle={item} />
        ))}
      </RelatedArticlesWrapper>
    </RelatedArticlesStyled>
  );
};

export default CmsRelatedArticles;
