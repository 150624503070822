import React, { useEffect } from 'react';
import { FooterBlocksStyled, FooterBlocksContainer } from './styled';
import {
  useCmsItem,
  StyledSpacingPropType,
} from '@thisisdevelopment/akzonobel-core';
import LinkCard from 'components/LinkCard';
import { LinkCardColor } from 'components/LinkCard/styled';

type FooterBlock = {
  title: string;
  headline: string;
  link: string;
  image: number;
  visible: string[];
  color: LinkCardColor;
};

type PropType = {
  page:
    | 'homepage'
    | 'the-heritage-story'
    | 'our-products'
    | 'for-professionals'
    | 'features';
};

function FooterBlocks({ page, ...rest }: PropType & StyledSpacingPropType) {
  const [footerBlocks, fetchFooterBlocks] =
    useCmsItem<FooterBlock[]>('footer_blocks');

  useEffect(() => {
    fetchFooterBlocks();
  }, [fetchFooterBlocks]);

  return (
    <FooterBlocksStyled {...rest}>
      <FooterBlocksContainer>
        {footerBlocks
          ?.filter(({ visible }) => visible.includes(page))
          .map(({ title, headline, link, image, color }, index) => (
            <LinkCard
              key={`FooterBlock_${index}`}
              to={link}
              title={title}
              text={headline}
              image={image}
              color={color}
            />
          ))}
      </FooterBlocksContainer>
    </FooterBlocksStyled>
  );
}

export default FooterBlocks;
