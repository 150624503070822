import styled, { css } from 'styled-components';

export const HueColorPickerStyled = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

type ColorHuePropType = {
  isSelected: boolean;
};

const ColorHueSelectedCSS = css`
  position: relative;
  transform: translateY(-5px) scale(1.3);
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  cursor: pointer;
  box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.3);
`;

export const ColorHue = styled.div<ColorHuePropType>`
  max-width: 100px;
  height: 90px;
  transition: all ease 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;

  &:hover {
    ${ColorHueSelectedCSS}
  }

  ${({ isSelected }) => isSelected && ColorHueSelectedCSS};
`;
