import styled, { css, keyframes } from 'styled-components';
import CmsImage from 'components/CmsImage';
import { ContainerInner } from 'elements';
import theme from 'theme';
import { camelCase } from 'lodash';

export type ImageAlign = 'right' | 'left';

export type SectionStyle =
  | 'white'
  | 'light-gray'
  | 'blue'
  | 'gray'
  | 'light-blue'
  | 'red'
  | 'full'
  | 'call2action'
  // book colors:
  | 'wild-blackberry'
  | 'oxford-blue'
  | 'midnight-teal'
  | 'sky-blue'
  | 'sage-green'
  | 'dark-stone'
  | 'raw-cashmere'
  | 'linnet-white'
  | 'dusted-heather'
  | 'blossom'
  | 'red-ochre'
  | 'florentine-red';

type StyledPropType = {
  imageAlign: ImageAlign;
  sectionStyle: SectionStyle;
  hasSpacing: boolean;
};

export const Content = styled(ContainerInner)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 0 40px 0;
`;

export const Image = styled(CmsImage)`
  object-fit: cover;
  width: 100%;

  @media ${theme.mediaQuery.desktop} {
    min-height: 300px;
    max-height: 600px;
  }

  @media ${theme.mediaQuery.tablet} {
    height: 400px;
  }

  @media ${theme.mediaQuery.mobile} {
    height: 200px;
  }
`;

const fadeInFocus = keyframes`
  from {
    opacity: 0;
    filter: blur(5px);
  }
  to {
    opacity: 1;
    filter: unset;
  }
`;

export const CmsSectionStyled = styled.section<StyledPropType>`
  position: relative;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column-reverse;

  animation: ${fadeInFocus} 0.8s;

  h3 {
    text-transform: uppercase;
    font-weight: 600;
  }

  p {
    margin: 25px 0;
  }

  a {
    font-family: 'Source Sans Pro', sans-serif;
    text-underline-position: under;
    font-weight: 600;
  }

  @media ${theme.mediaQuery.tablet} {
    h3 {
      font-size: 1.2rem;
      color: ${({ theme }) => theme.colors.brandGold};
    }

    p {
      font-size: 1rem;
      color: ${({ theme }) => theme.colors.black};
    }

    a {
      font-size: 1rem;
      color: ${({ theme }) => theme.colors.brandGold};
    }
  }

  @media ${theme.mediaQuery.desktop} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    h3 {
      font-size: 1.5rem;
    }

    p {
      font-size: 1.1rem;
    }

    a {
      font-size: 1.1rem;
    }

    > :first-child {
      order: ${({ imageAlign }) => (imageAlign === 'right' ? '1' : '2')};
    }

    > :nth-child(2) {
      order: ${({ imageAlign }) => (imageAlign === 'right' ? '2' : '1')};
    }

    ${({ hasSpacing }) =>
      hasSpacing &&
      css`
        margin: 40px 0;
      `}

    ${({ sectionStyle, theme }) =>
      sectionStyle === 'white' &&
      css`
        background-color: ${theme.colors.white};

        h3 {
          color: ${theme.colors.brandGold};
        }

        p {
          color: ${theme.colors.black};
        }

        a {
          color: ${theme.colors.brandGold};
        }
      `}

    ${({ sectionStyle, theme }) =>
      sectionStyle === 'light-gray' &&
      css`
        background-color: ${theme.colors.gray2};

        h3 {
          color: ${theme.colors.brandGold};
        }

        p {
          color: ${theme.colors.brandBlue};
        }

        a {
          color: ${theme.colors.brandGold};
        }
      `}

    ${({ sectionStyle, theme }) =>
      sectionStyle === 'blue' &&
      css`
        background-color: ${theme.colors.brandBlueDarker};

        h3 {
          color: ${theme.colors.white};
        }

        p {
          color: ${theme.colors.white};
        }

        a {
          color: ${theme.colors.brandGold};
        }
      `}

    ${({ sectionStyle, theme }) =>
      sectionStyle === 'gray' &&
      css`
        background-color: ${theme.colors.brandGoldenGray};

        h3 {
          color: ${theme.colors.white};
        }

        p {
          color: ${theme.colors.white};
        }

        a {
          color: ${theme.colors.brandBlueDarker};
        }
      `}

    ${({ sectionStyle, theme }) =>
      sectionStyle === 'light-blue' &&
      css`
        background-color: ${theme.colors.brandBlue};

        h3 {
          color: ${theme.colors.brandGold};
        }

        p {
          color: ${theme.colors.white};
        }

        a {
          color: ${theme.colors.brandGold};
        }
      `}

    ${({ sectionStyle, theme }) =>
      sectionStyle === 'red' &&
      css`
        background-color: ${theme.colors.brandRed};

        h3 {
          color: ${theme.colors.brandGold};
        }

        p {
          color: ${theme.colors.white};
        }

        a {
          color: ${theme.colors.brandGold};
        }
      `}

    ${({ sectionStyle, theme }) =>
      sectionStyle === 'full' &&
      css`
        grid-template-columns: 1fr;

        h3 {
          color: ${theme.colors.brandBlueDarker};
        }

        p {
          color: ${theme.colors.brandBlueDarker};
        }

        a {
          color: ${theme.colors.brandGold};
        }

        ${Content} {
          position: absolute;
          top: 0;
          bottom: 0;
          width: 500px;
        }
      `}

      ${({ sectionStyle, theme }) =>
      sectionStyle === 'call2action' &&
      css`
        grid-template-columns: 1fr;

        h3 {
          font-family: 'Source Serif Pro', serif;
          color: ${theme.colors.brandGold};
          font-style: italic;
          font-weight: 400;
          font-size: 2.4rem;
          text-transform: unset;
          line-height: 1em;
          margin: 80px 0 0 0;
        }

        h4 {
          font-family: 'Source Sans Pro', sans-serif;
          color: ${({ theme }) => theme.colors.white};
          font-weight: 600;
          font-size: 2.4rem;
          text-transform: uppercase;
          letter-spacing: 0.1em;
          line-height: 1em;
          margin: 0;
        }

        p {
          display: none;
        }

        a {
          color: ${theme.colors.brandGold};
          margin-top: 40px;
        }

        ${Content} {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      `}
  }

  ${({ sectionStyle, theme }) =>
    (sectionStyle === 'wild-blackberry' ||
      sectionStyle === 'oxford-blue' ||
      sectionStyle === 'midnight-teal' ||
      sectionStyle === 'sky-blue' ||
      sectionStyle === 'sage-green' ||
      sectionStyle === 'dark-stone' ||
      sectionStyle === 'dusted-heather' ||
      sectionStyle === 'blossom' ||
      sectionStyle === 'red-ochre' ||
      sectionStyle === 'florentine-red') &&
    css`
      text-align: center;
      background-color: ${theme.colors[
        camelCase(sectionStyle) as keyof typeof theme.colors
      ]};

      h3 {
        color: ${theme.colors.white};
        font-size: 28px;
        font-weight: 600;
      }
      h4 {
        color: ${theme.colors.white};
        font-size: 22px;
        font-weight: 400;
      }

      p {
        color: ${theme.colors.white};
        font-size: 16px;
        font-weight: normal;
      }

      a {
        color: ${theme.colors.white};
        font-size: 20px;
        font-weight: 600;
      }
    `}

  ${({ sectionStyle, theme }) =>
    (sectionStyle === 'raw-cashmere' || sectionStyle === 'linnet-white') &&
    css`
      text-align: center;
      background-color: ${theme.colors[
        camelCase(sectionStyle) as keyof typeof theme.colors
      ]};

      h3 {
        color: ${theme.colors.brandBlue};
        font-size: 28px;
        font-weight: 600;
      }

      h4 {
        color: ${theme.colors.brandBlue};
        font-size: 22px;
        font-weight: 400;
      }

      p {
        color: ${theme.colors.brandBlue};
        font-size: 16px;
        font-weight: normal;
      }

      a {
        color: ${theme.colors.brandBlue};
        font-size: 20px;
        font-weight: 600;
      }
    `}
`;
