import styled from 'styled-components';
import { Paragraph, H4 } from '../../elements';

export const FindStoresStyled = styled.div`
  display: grid;
  grid-template-columns: 400px 1fr;
  grid-template-rows: calc(100vh - 270px);
  gap: 20px;
  border-radius: 4px;
  border: 1px;
  border-color: ${(props) => props.theme.findStore?.borderColor};
  background-color: ${(props) => props.theme.findStore?.bgColor};
  color: ${(props) => props.theme.findStore?.fontColor};

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-template-rows: 500px 500px;
  }
`;

export const Title = styled(H4)`
  font-size: 1.5rem;

  @media (max-width: 480px) {
    font-size: 1.3rem;
  }
`;

export const Headline = styled(Paragraph)``;
