import { css } from 'styled-components';

export type StyledSizePropType = {
  height?: string;
  width?: string;
  minHeight?: string;
  minWidth?: string;
  maxHeight?: string;
  maxWidth?: string;
  objectFit?:
    | 'contain'
    | 'cover'
    | 'fill'
    | 'scale-down'
    | 'inherit'
    | 'initial'
    | 'unset'
    | 'none';
};

export const handleSize = (props: StyledSizePropType) => css`
  height: ${props.height};
  width: ${props.width};
  min-height: ${props.minHeight};
  min-width: ${props.minWidth};
  max-height: ${props.maxHeight};
  max-width: ${props.maxWidth};
  object-fit: ${props.objectFit};
`;
