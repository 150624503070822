import Cart from '../../assets/images/cart';
import Bucket from '../../assets/images/bucket';
import Minus from '../../assets/images/minus';
import Heart from '../../assets/images/heart';
import Download from '../../assets/images/download';
import Pdf from '../../assets/images/pdf';
import KeyInfo from '../../assets/images/key-info';
import KeyInfoFinish from '../../assets/images/key-info-finish';
import KeyInfoCoverage from '../../assets/images/key-info-coverage';
import KeyInfoDrying from '../../assets/images/key-info-drying';
import KeyInfoCoats from '../../assets/images/key-info-coats';
import Check from '../../assets/images/check';
import Plus from '../../assets/images/plus';
import SelectYourColor from '../../assets/images/select-your-color';
import Close from '../../assets/images/close';
import AddCircle from '../../assets/images/add-circle';
import Carret from '../../assets/images/carret';
import ArrowRight from '../../assets/images/arrow-right';
import FiltersIcon from '../../assets/images/filters';
import MarkerEmpty from '../../assets/images/marker-empty';
import MarkerFilled from '../../assets/images/marker-filled';
import LocationPicker from '../../assets/images/location-picker';
import Search from '../../assets/images/search';
import Menu from '../../assets/images/menu';
import ShoppingBag from '../../assets/images/shopping-bag';
import Person from '../../assets/images/person';
import ExpandLess from '../../assets/images/expand-less';
import ExpandMore from '../../assets/images/expand-more';
import ColorCardIcon from '../../assets/images/color-card-icon';
import EmailIcon from '../../assets/images/email-icon';

export default {
  Cart,
  Bucket,
  Minus,
  Heart,
  Download,
  Pdf,
  KeyInfo,
  KeyInfoFinish,
  KeyInfoCoverage,
  KeyInfoDrying,
  KeyInfoCoats,
  Check,
  Plus,
  SelectYourColor,
  Close,
  AddCircle,
  Carret,
  ArrowRight,
  FiltersIcon,
  MarkerEmpty,
  MarkerFilled,
  LocationPicker,
  Search,
  Menu,
  ShoppingBag,
  Person,
  ExpandLess,
  ExpandMore,
  ColorCardIcon,
  EmailIcon,
};
