import {
  Anchor,
  H3,
  List,
  ListItem,
  Wrapper,
  config,
} from '@thisisdevelopment/akzonobel-core';
import FacebookLogo from 'assets/images/facebook';
import InstagramLogo from 'assets/images/instagram';
import PinterestLogo from 'assets/images/pinterest';
import { ContainerInner } from 'elements';
import Link from 'next/link';
import {
  Bottom,
  FooterStyled,
  Links,
  LinksContainer,
  NewsLetterLink,
  SocialWrapper,
  Social,
} from './styled';

function Footer() {
  const year = new Date().getFullYear();

  return (
    <FooterStyled>
      <Links>
        <ContainerInner>
          <LinksContainer>
            <Wrapper>
              <H3>Dulux Heritage</H3>
              <List>
                <ListItem>
                  <Link href="/en/contact-us">Contact us</Link>
                </ListItem>
                <ListItem>
                  <Link href="/en/stores">Find a Stockist</Link>
                </ListItem>
                {config.ecomEnabled && (
                  <ListItem>
                    <Link href="/en/delivery-and-returns-policy">
                      Refunds and Cancellations
                    </Link>
                  </ListItem>
                )}
                <ListItem>
                  <Link href="/en/terms-and-conditions">
                    Terms and Conditions
                  </Link>
                </ListItem>
              </List>
            </Wrapper>
            <Wrapper>
              <H3>Access</H3>
              <List>
                <ListItem>
                  <Link href="/en/cookie-policy">Cookie Policy</Link>
                </ListItem>
                {config.showCookieSettings && (
                  <ListItem>
                    <a
                      className="optanon-toggle-display"
                      style={{ cursor: 'pointer' }}
                      href="#"
                      target="_self"
                    >
                      Cookie Settings
                    </a>
                  </ListItem>
                )}
                <ListItem>
                  <Link href="/en/privacy-policy">Privacy Policy</Link>
                </ListItem>
                <ListItem>
                  <Link href="/en/akzonobel-modern-slavery-statement">
                    Modern Slavery Statement
                  </Link>
                </ListItem>
                <ListItem>
                  <Link href="/en/ici-gender-pay-report">
                    ICI Gender Pay Report
                  </Link>
                </ListItem>
              </List>
            </Wrapper>
            <Wrapper>
              <H3>Related sites</H3>
              <List>
                {config.relatedSites &&
                  config.relatedSites.length > 0 &&
                  config.relatedSites.map((relatedSite, idx) => {
                    return (
                      <ListItem key={idx}>
                        <Anchor
                          href={relatedSite.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {relatedSite.title}
                        </Anchor>
                      </ListItem>
                    );
                  })}
              </List>
            </Wrapper>
            <Wrapper>
              <H3>Follow us on</H3>
              <SocialWrapper>
                <Social>
                  {config.socials?.facebook && (
                    <Anchor
                      href={config.socials?.facebook}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FacebookLogo />
                    </Anchor>
                  )}
                  {config.socials?.instagram && (
                    <Anchor
                      href={config.socials?.instagram}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <InstagramLogo />
                    </Anchor>
                  )}
                  {config.socials?.pinterest && (
                    <Anchor
                      href={config.socials?.pinterest}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <PinterestLogo />
                    </Anchor>
                  )}
                </Social>
                <NewsLetterLink href="/en/newsletter">
                  Subscribe to our newsletter
                </NewsLetterLink>
              </SocialWrapper>
            </Wrapper>
          </LinksContainer>
        </ContainerInner>
      </Links>
      <Bottom>
        <ContainerInner>Copyright © {year} - AkzoNobel</ContainerInner>
      </Bottom>
    </FooterStyled>
  );
}

export default Footer;
