import { css } from 'styled-components';

type FontSize =
  | 'xxxs'
  | 'xxs'
  | 'xs'
  | 'sm'
  | 'md'
  | 'lg'
  | 'xl'
  | 'xxl'
  | 'xxxl';

const fontSize = {
  xxxs: '0.6rem',
  xxs: '0.7rem',
  xs: '0.8rem',
  sm: '0.9rem',
  md: '1rem',
  lg: '1.1rem',
  xl: '1.2rem',
  xxl: '1.6rem',
  xxxl: '1.8rem',
};

type FontWeight =
  | 'light'
  | 'semilight'
  | 'normal'
  | 'semibold'
  | 'bold'
  | 'extrabold';

const fontWeight = {
  light: 100,
  semilight: 200,
  normal: 300,
  semibold: 400,
  bold: 500,
  extrabold: 600,
};

type TextAlign = 'center' | 'justify' | 'left' | 'right';

export type StyledTextPropType = {
  fontSize?: FontSize;
  fontWeight?: FontWeight;
  textAlign?: TextAlign;
  fontFamily?: string;
  color?: string;
  lineHeight?: string;
};

export const handleText = (props: StyledTextPropType) => css`
  text-align: ${props.textAlign};
  font-size: ${props.fontSize && fontSize[props.fontSize]};
  font-weight: ${props.fontWeight && fontWeight[props.fontWeight]};
  font-family: ${props.fontFamily};
  color: ${props.color};
  line-height: ${props.lineHeight};
`;
