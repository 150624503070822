import axios from 'axios';

const headers = {
  'Cache-Control': 'no-cache',
  Pragma: 'no-cache',
  Expires: '0',
};

const axiosInstance = axios.create({ withCredentials: true, headers });

export function initAxiosInstance(baseURL: string) {
  axiosInstance.defaults.baseURL = baseURL;
}

export default axiosInstance;
