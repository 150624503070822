import { H4, Paragraph } from '@thisisdevelopment/akzonobel-core';
import CmsImage from 'components/CmsImage';
import { isExternalLink } from 'util/path';
import { LinkCardStyled, StyledPropTypes } from './styled';

type PropTypes = {
  title: string;
  text?: string;
  to: string;
  image: number;
};

function LinkCard({
  title,
  text,
  to,
  image,
  color,
}: PropTypes & StyledPropTypes) {
  const children = (
    <>
      <CmsImage id={image} alt={'Link Card Image'} />
      <H4 mt="20px">{title}</H4>
      {text && <Paragraph>{text}</Paragraph>}
    </>
  );

  return to && isExternalLink(to) ? (
    <LinkCardStyled color={color} href={to} target="_blank">
      <>{children}</>
    </LinkCardStyled>
  ) : (
    <LinkCardStyled color={color} href={to ?? '/'}>
      <>{children}</>
    </LinkCardStyled>
  );
}

export default LinkCard;
