import React, { ReactElement } from 'react';
import { StyledSpacingPropType } from '@thisisdevelopment/akzonobel-core';
import { ButtonStyled, StyledPropTypes, LinkText } from './styled';

type PropTypes = {
  hasBackground?: boolean;
  onClick: () => void;
  label: string;
  icon?: ReactElement<any, any>;
};

function OutlinedButton({
  onClick,
  label,
  icon,
  ...rest
}: PropTypes & StyledPropTypes & StyledSpacingPropType) {
  return (
    <ButtonStyled onClick={() => onClick()} {...rest}>
      {icon}
      <LinkText>{label}</LinkText>
    </ButtonStyled>
  );
}

export default OutlinedButton;
