import {
  Color,
  getColorNameByBgColorHex,
} from '@thisisdevelopment/akzonobel-core';
import { useRouter } from 'next/router';
import { ColorBlock, ColorCardStyled, ColorName, ColorNameIn } from './styled';

type PropTypes = {
  color: Color;
  mode: 'Product' | 'Color';
};

function ColorCard({ color, mode }: PropTypes) {
  const textColor = getColorNameByBgColorHex(color.rgb);
  const router = useRouter();
  const { friendlyName } = router.query;

  const queryWithCccid = new URLSearchParams({
    ...router.query,
    cccid: color.colorCollectionColorId.toString(),
  });

  queryWithCccid.delete('friendlyName');

  const query = new URLSearchParams(
    router.query as Record<string, string>
  ).toString();

  const linkTo =
    mode === 'Product'
      ? `/en/products/details/${friendlyName}?${queryWithCccid}`
      : `/en/colours/${color.colorCollectionColorId}/${color.uriFriendlyName}${
          query ? `?${query}` : ''
        }`;

  return (
    <ColorCardStyled
      href={linkTo}
      key={`ColorCard_${color.colorCollectionColorId}`}
    >
      <ColorBlock bgColor={`#${color.rgb}`}>
        <ColorNameIn textColor={textColor}>
          {color.defaultColorName}
        </ColorNameIn>
      </ColorBlock>
      <ColorName>{color.defaultColorName}</ColorName>
    </ColorCardStyled>
  );
}

export default ColorCard;
