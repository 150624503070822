import styled from 'styled-components';
import { Wrapper } from '@thisisdevelopment/akzonobel-core';
import theme from 'theme';

export const Content = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  background-color: ${({ theme }) => `${theme.colors.brandBlue}F2`}; // 95% opacity
  color: ${({ theme }) => theme.colors.white};
  padding: 40px;
  border-radius: 0 0 4px 4px;
  gap: 40px;

  @media ${theme.mediaQuery.tablet} {
    padding: 20px;
    gap: 20px;
  }

  @media ${theme.mediaQuery.mobile} {
    flex-direction: column;
    padding: 20px 0 0 0;
  }
`;

export const ImageWrapper = styled(Wrapper)`
  display: flex;
  justify-content: center;

  img {
    max-width: 350px;
    max-height: 350px;
    object-fit: cover;
  }

  @media ${theme.mediaQuery.tablet} {
    img {
      max-width: 200px;
      max-height: 200px;
      object-fit: cover;
    }
  }

  @media ${theme.mediaQuery.mobile} {
    width: 100%;

    img {
      max-width: 100%;
      max-height: 250px;
    }
  }
`;

export const ProductCardStyled = styled.article`
  position: relative;
  padding: 60px 100px;

  > img {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 100%;
    z-index: 1;
    object-fit: cover
  }

  &:nth-child(odd) {
    ${Content} {
      flex-direction: row;
    }
  }

  &:nth-child(even) {
    ${Content} {
      flex-direction: row-reverse;
    }
  }

  @media ${theme.mediaQuery.tablet} {
    padding: 30px;
  }

  @media ${theme.mediaQuery.mobile} {
    padding: unset;

    &:nth-child(odd) {
      ${Content} {
        flex-direction: column;
      }
    }

    &:nth-child(even) {
      ${Content} {
        flex-direction: column;
      }
    }

    > img {
      height: 300px;
    }
  }
`;

export const InfoWrapper = styled(Wrapper)`
  flex: 1;
  border-radius: 0 0 4px 4px;

  h4 {
    font-size: 1.5rem;
    font-weight: 600;
  }

  @media ${theme.mediaQuery.mobile} {
    background-color: ${({ theme }) => `${theme.colors.brandBlue}`};
    padding: 30px;

    h4 {
      font-size: 1.3rem;
      text-transform: uppercase;
      color: ${({ theme }) => theme.colors.brandGold};
    }
  }
`;

export const ProductOptions = styled.div`
  margin-top: 30px;
  display: flex;
  gap: 20px;

  button {
    width: auto;
    padding-left: 30px;
    padding-right: 30px;
    text-transform: uppercase;
  }

  @media ${theme.mediaQuery.mobile} {
    flex-direction: column;

    button {
      width: 100%;
      padding-left: unset;
      padding-right: unset;
    }
  }
`;
