import React from "react"

const Check = ({ ...props }) => {
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
            <path fillRule="evenodd" d="M10.466 30a2.005 2.005 0 01-1.552-.736L.449 18.875a2 2 0 013.102-2.527l6.913 8.485L28.449 2.737a2 2 0 113.102 2.526l-19.535 24a1.999 1.999 0 01-1.55.737"></path>
        </svg>
    )
}

export default Check
