import { H2, Paragraph } from '@thisisdevelopment/akzonobel-core';
import CmsImage from 'components/CmsImage';
import { useMediaQuery } from 'hooks/useMediaQuery';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { FC } from 'react';
import theme from 'theme';
import {
  Content,
  Image,
  InpirationCardStyled,
  InspirationReadMoreLink,
  SubCategory,
} from './styled';

//TODO Pulled from pages/Inspiration, check if this is ok, now we have model duplication and issues when we change the model, is there a better location for (shared) models?
export type InspirationType = {
  id: number;
  title: string;
  headline: string;
  summary: string;
  body: string;
  image: number;
  thumbnail: number;
  category: string[];
  sub_category: string;
  highlight: boolean;
  friendly_url_title: string;
  modified_on: Date;
};

type PropType = {
  article: InspirationType;
  highlight?: boolean;
};

const InspirationCard: FC<PropType> = ({
  article,
  highlight = false,
}: PropType) => {
  const isMobile = useMediaQuery(theme.mediaQuery.mobile);
  const linkTo = `/en/inspiration/article/${article.id}/${article.friendly_url_title}`;
  const router = useRouter();

  const showArticle = () => {
    if (!isMobile) {
      router.push(linkTo);
    }
  };

  return (
    <InpirationCardStyled $highlight={highlight} as="div" onClick={showArticle}>
      <Image>
        <Link href={linkTo}>
          <CmsImage id={article.image} alt="Inspirational Article Image" />
        </Link>
        <SubCategory>{article.sub_category}</SubCategory>
      </Image>
      <Content>
        <H2>{article.title}</H2>
        <Paragraph>
          {article.headline}
          <InspirationReadMoreLink href={linkTo} forceShow={highlight}>
            Read more
          </InspirationReadMoreLink>
        </Paragraph>
      </Content>
    </InpirationCardStyled>
  );
};

export default InspirationCard;
