import styled, { css, keyframes } from 'styled-components';

export const CartStyled = styled.a`
  position: relative;
  padding-top: 9px;
  padding-right: 7px;

  svg {
    fill: ${({ theme }) => theme.cart?.color};
    width: 24px;
    height: 24px;
    transition: all ease 0.3s;
  }

  &:hover {
    svg {
      fill: ${({ theme }) => theme.cart?.hoverColor};
      cursor: pointer;
    }
  }
`;

const changingAnimation = keyframes`
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
`;

type BadgePropTypes = {
  changing: boolean;
};

export const Badge = styled.div<BadgePropTypes>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background-color: ${({ theme }) => theme.cart?.badgeColor};
  color: ${({ theme }) => theme.colors.white};
  font-size: 0.8rem;

  ${({ changing }) =>
    changing &&
    css`
      animation: ${changingAnimation} 0.5s;
    `};
`;
