import React from 'react';
import Svg from '../../components/Svg';
import { LikeButtonStyled } from './styled';

type PropTypes = {
  onClick: Function;
  top?: string;
  right?: string;
  stroke?: string;
};

function LikeButton({ onClick, top = '15px', right = '15px', stroke = '#CCC' }: PropTypes) {
  const onClickStopPropagation = (e: React.MouseEvent) => {
    e.stopPropagation();
    onClick();
  };

  return (
    <LikeButtonStyled top={top} right={right}>
      <Svg.Heart
        strokeWidth="2px"
        color="transparent"
        width="18px"
        style={{ stroke }}
        onClick={onClickStopPropagation}
      />
    </LikeButtonStyled>
  );
}

export default LikeButton;
