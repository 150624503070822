import { css } from 'styled-components';

export type ThemeMixins = {
  underlineHover: any;
  underlineHovered: any;
};

const underlineHover = (color: string) => css`
  position: relative;
  text-decoration: none;
  padding: 3px 0;
  color: ${color};

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: ${color};
    height: 1px;
    width: 0;
    opacity: 0;
    margin: 0 auto;
    transition: all ease 0.2s;
  }
`;

const underlineHovered = css`
  cursor: pointer;

  &:after {
    width: 100%;
    opacity: 1;
  }
`;

export const mixins: ThemeMixins = { underlineHover, underlineHovered };
